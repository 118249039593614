import { tv, type VariantProps } from 'tailwind-variants'

import styles from './LoadingLogo.module.scss'

const svgVariants = tv({
  base: 'not-sr-only',
  variants: {
    size: {
      default: 'size-6',
      sm: 'size-3',
      md: 'size-4',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

type SvgVariantProps = VariantProps<typeof svgVariants>

interface LoadingLogoProps extends SvgVariantProps {
  isLoading?: boolean
  className?: string
}

export function LoadingLogo({ size = 'default', isLoading = true }: LoadingLogoProps) {
  return (
    <span className={styles.loadingLogo}>
      <svg
        width='104'
        height='109'
        viewBox='0 0 104 109'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={svgVariants({ size })}
      >
        <g className={styles.background}>
          <path
            d='M51.6523 68.5288C51.6523 72.9791 52.8803 77.1541 55.1256 81.0452C57.3708 84.9362 60.3119 87.717 64.156 90.0376C69.5742 93.0052 73.5668 94.1289 79.4382 93.9787C81.5905 93.9533 84.171 93.7768 86.2915 93.4066'
            strokeWidth='6'
            strokeLinecap='round'
            className='bg'
          />
          <path
            d='M80.9665 68.9459C80.9665 53.0919 66.9347 42.6616 51.6525 42.6616C36.3703 42.6616 22.8942 54.2045 22.8942 67.9725C22.7693 74.8053 23.8667 81.4623 27.4788 88.5549C31.091 95.6475 35.4514 100.489 39.4267 103.992'
            strokeWidth='6'
            strokeLinecap='round'
            className='bg'
          />
          <path
            d='M12.4738 89.1113C10.112 82.8531 8.72268 78.8201 8.44482 69.6414C8.23432 62.6879 9.97304 56.0125 13.4463 50.1716C21.5505 36.5427 37.2031 29.7282 51.6518 29.5891C68.4353 29.4276 82.9622 38.083 90.274 50.3106C94.0995 56.7079 95.4144 63.1051 95.4144 68.5289C95.4144 76.3168 88.1949 81.6015 81.2437 81.6015C74.0193 81.6015 66.6561 77.7075 66.3782 68.5289C66.1004 59.3502 57.6257 55.7344 51.6518 55.7344C43.6245 55.7344 37.2031 61.7144 37.2031 68.5289C37.2031 72.8385 38.5075 78.6384 40.2596 82.575C42.0117 86.5116 45.1253 91.0871 48.3175 93.9788C53.2087 98.6432 59.4318 102.879 68.508 104.949'
            strokeWidth='6'
            strokeLinecap='round'
            className='bg'
          />
          <path
            d='M100 39.7412C94.9986 32.7876 87.6353 26.7902 78.7439 22.6355C70.8368 18.9407 60.7901 16.9336 52.064 16.9336C43.3379 16.9336 33.0243 18.9407 25.1172 22.6355C16.5534 26.6371 8.86252 33.0665 4 39.8802'
            strokeWidth='6'
            strokeLinecap='round'
            className='bg'
          />
          <path
            d='M84.856 11.7879C75.5798 7.08849 63.7388 4 52.1034 4C39.843 4 28.4188 7.22756 19.1426 11.927'
            strokeWidth='6'
            strokeLinecap='round'
            className='bg'
          />
        </g>
        <g className={styles.animate}>
          <path
            d='M51.6523 68.5288C51.6523 72.9791 52.8803 77.1541 55.1256 81.0452C57.3708 84.9362 60.3119 87.717 64.156 90.0376C69.5742 93.0052 73.5668 94.1289 79.4382 93.9787C81.5905 93.9533 84.171 93.7768 86.2915 93.4066'
            strokeWidth='6'
            strokeLinecap='round'
          />
          <path
            d='M80.9665 68.9459C80.9665 53.0919 66.9347 42.6616 51.6525 42.6616C36.3703 42.6616 22.8942 54.2045 22.8942 67.9725C22.7693 74.8053 23.8667 81.4623 27.4788 88.5549C31.091 95.6475 35.4514 100.489 39.4267 103.992'
            strokeWidth='6'
            strokeLinecap='round'
          />
          <path
            d='M12.4738 89.1113C10.112 82.8531 8.72268 78.8201 8.44482 69.6414C8.23432 62.6879 9.97304 56.0125 13.4463 50.1716C21.5505 36.5427 37.2031 29.7282 51.6518 29.5891C68.4353 29.4276 82.9622 38.083 90.274 50.3106C94.0995 56.7079 95.4144 63.1051 95.4144 68.5289C95.4144 76.3168 88.1949 81.6015 81.2437 81.6015C74.0193 81.6015 66.6561 77.7075 66.3782 68.5289C66.1004 59.3502 57.6257 55.7344 51.6518 55.7344C43.6245 55.7344 37.2031 61.7144 37.2031 68.5289C37.2031 72.8385 38.5075 78.6384 40.2596 82.575C42.0117 86.5116 45.1253 91.0871 48.3175 93.9788C53.2087 98.6432 59.4318 102.879 68.508 104.949'
            strokeWidth='6'
            strokeLinecap='round'
          />
          <path
            d='M100 39.7412C94.9986 32.7876 87.6353 26.7902 78.7439 22.6355C70.8368 18.9407 60.7901 16.9336 52.064 16.9336C43.3379 16.9336 33.0243 18.9407 25.1172 22.6355C16.5534 26.6371 8.86252 33.0665 4 39.8802'
            strokeWidth='6'
            strokeLinecap='round'
          />
          <path
            d='M84.856 11.7879C75.5798 7.08849 63.7388 4 52.1034 4C39.843 4 28.4188 7.22756 19.1426 11.927'
            strokeWidth='6'
            strokeLinecap='round'
          />
        </g>
      </svg>
      {isLoading ? <span className='sr-only'>Loading...</span> : null}
    </span>
  )
}
