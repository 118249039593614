import { CustomDateRange, PredefinedRange } from 'helpers/dateRange'
import { useMemo } from 'react'

import { Value } from './useApiCallsComparison'

export function useApiCallsTooltipData(
  tooltipIndex: number | undefined,
  values: Value[],
  dateRange: CustomDateRange | PredefinedRange | undefined,
  compareValues: Value[]
) {
  return useMemo(() => {
    if (tooltipIndex == null) {
      return null
    }
    return {
      currentPeriodStart: values?.[tooltipIndex]?.timestamp ?? dateRange?.startDate,
      currentPeriodEnd: values?.[tooltipIndex + 1]?.timestamp ?? dateRange?.endDate,
      currentValue: values?.[tooltipIndex]?.value,
      previousPeriodStart: compareValues?.[tooltipIndex]?.originalTimestamp,
      previousPeriodEnd: compareValues?.[tooltipIndex + 1]?.originalTimestamp ?? dateRange?.endDate,
      previousValue: compareValues?.[tooltipIndex]?.value,
    }
  }, [compareValues, dateRange?.endDate, dateRange?.startDate, tooltipIndex, values])
}
