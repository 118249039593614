import { Button } from '@compass/components'
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { ErrorOutline, MailOutline, VideoCallOutlined } from '@mui/icons-material'
import { Box, Checkbox, FormControlLabel, InputLabel, Link, MenuItem, Paper, Select, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import clsx from 'clsx'
import FullpageLoader from 'components/Loader/FullpageLoader/FullpageLoader'
import { Stepper } from 'components/Stepper/Stepper'
import { TextFieldWithCounter } from 'features/commonUI'
import { useToast } from 'hooks'
import { useBdrMeetingScheduled, useContactSalesEnterpriseClosure } from 'hooks/api/bdr_meeting'
import { useChiliPiper } from 'hooks/useChiliPiper'
import { ampli } from 'models/ampli'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SUPPORT_PAGE_URL } from '../../../../const'
import { useCurrentUser } from '../../../../hooks/api/users'
import { MessageData, Preview } from '../../../commonUI/components/Preview/Preview'
import styles from './Form.module.scss'
import surveyBotAvatar from './surveybot.svg?url'

export enum Step {
  ContactSales,
  BookCall,
}

const STEP_LABELS: Record<Step, string> = {
  [Step.ContactSales]: 'Contact sales',
  [Step.BookCall]: 'Book call',
}

const apiCallsOptions = [
  {
    label: '0-100,000',
    value: 'Less than 1 million',
  },
  {
    label: '100,000-500,000',
    value: 'Between 1 million and 6 million',
  },
  {
    label: '500,000+',
    value: 'More than 6 million',
  },
]

const messageMaxLength = 500

export type FormProps = {
  slackPreviewMessages: MessageData[]
}

export function Form({ slackPreviewMessages }: FormProps) {
  const { data: user, isLoading: isLoadingUser } = useCurrentUser()
  const [activeStep, setActiveStep] = useState(Step.ContactSales)
  const [previewMessage, setPreviewMessage] = useState('')
  const [apiCalls, setApiCalls] = useState('')
  const [messageText, setMessageText] = useState('')
  const { showToast } = useToast()

  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const { mutate: submitBdrMeetingScheduled, isLoading: isLoadingMeeting } = useBdrMeetingScheduled()
  const { mutate: submitContactSalesEnterpriseClosure } = useContactSalesEnterpriseClosure()
  const { isLoading: isLoadingVisitorData } = useVisitorData({ extendedResult: true })
  const [chiliPiperLoaded, setChiliPiperLoaded] = useState(false)
  const [chiliPiperScheduled, setChiliPiperScheduled] = useState(false)
  const [isCallRequested, setIsCallRequested] = useState(true)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const history = useHistory()

  const {
    isBlocked: chiliPiperBlocked,
    bookingUrl,
    hasError: chiliPiperError,
    initialize: initializeChiliPiper,
    visitorRegion,
  } = useChiliPiper()

  const goBack = () => {
    if (!isFormSubmitted && !(chiliPiperBlocked && bookingUrl)) {
      submitContactSalesEnterpriseClosure({})
    }
    history.push('/')
  }

  const steps = useMemo(() => {
    if (apiCalls === '500,000+') {
      return [
        { label: STEP_LABELS[Step.ContactSales], icon: <MailOutline sx={{ width: '20px', height: '20px' }} /> },
        { label: STEP_LABELS[Step.BookCall], icon: <VideoCallOutlined sx={{ width: '20px', height: '20px' }} /> },
      ]
    }
    return [{ label: STEP_LABELS[Step.ContactSales], icon: <MailOutline sx={{ width: '20px', height: '20px' }} /> }]
  }, [apiCalls])

  const updateMessage = (selectedApiCalls: string, selectedMessageText: string) => {
    if (selectedApiCalls === '' && selectedMessageText === '') {
      return setPreviewMessage('')
    }
    const combinedMessage = [selectedApiCalls, selectedMessageText].filter(Boolean).join(', ').trim()
    setMessageText(selectedMessageText)
    setPreviewMessage(`${user?.name ?? 'User'} says: ${combinedMessage}`)
  }

  useEffect(() => {
    if (user) {
      initializeChiliPiper(user)
    }
  }, [user, initializeChiliPiper])

  useEffect(() => {
    if (activeStep === Step.BookCall && !chiliPiperLoaded) {
      if (chiliPiperBlocked && bookingUrl) {
        window.open(bookingUrl, '_blank')
        history.push('/')
      } else if (window.ChiliPiper && user) {
        window.ChiliPiper.submit('fingerprint', 'non_developer_onboarding', {
          domElement: '#chiliModal',
          map: true,
          lead: {
            firstName: user.email.split('@')[0].slice(0, 20),
            lastName: user.email.split('@')[1].slice(0, 20),
            email: user.email,
            apiCalls: 'More than 6 million',
            ipRegion: visitorRegion,
          },
          injectRootCss: true,
          onSuccess: () => setChiliPiperScheduled(true),
          onError: () => {
            ampli.appSalesCallSchedulingFailed({
              'App Page Path': AppRoute.ContactSalesEnterprise,
              customerID: user.customerId,
            })
          },
        })
        setChiliPiperLoaded(true)
      }
    }
  }, [activeStep, chiliPiperLoaded, user, visitorRegion, chiliPiperBlocked, bookingUrl, history])

  const submitEnterpriseRequest = () => {
    if (user) {
      setIsFormSubmitted(true)

      const finalMessage = chiliPiperBlocked
        ? `${messageText} [Note: User experienced issues with ChiliPiper, likely due to ad blocker]`
        : messageText

      submitBdrMeetingScheduled(
        {
          data: {
            mail: user.email,
            ipRegion: visitorRegion,
            message: finalMessage,
            apiCalls,
          },
        },
        {
          onSuccess: () => {
            ampli.appFormSubmitted({
              'App Page Path': AppRoute.ContactSalesEnterprise,
              formName: 'Upgrade To Enterprise',
            })
            showToast({
              message: 'Thanks! Your request for Fingerprint Enterprise was received.',
              severity: 'success',
              duration: chiliPiperBlocked && apiCalls === '500,000+' ? 10000 : 3000,
            })
            apiCalls === '500,000+' ? setActiveStep(Step.BookCall) : history.push('/')
          },
          onError: () => {
            showToast({
              message: 'An error occurred when submitting your form. Please contact support if this error persists.',
              severity: 'error',
            })
          },
        }
      )
    }
  }

  const captureEvent = useCallback(
    (event: MessageEvent) => {
      if (event.data && typeof event.data === 'object' && event.data.action === 'booking-confirmed' && user) {
        submitBdrMeetingScheduled({
          data: {
            mail: user.email,
            assigneeId: event.data.args.assigneeId,
            slot: event.data.args.slot,
            ipRegion: visitorRegion,
          },
        })
        ampli.appSalesCallScheduled({ 'App Page Path': AppRoute.ContactSalesEnterprise })
      }
    },
    [user, visitorRegion, submitBdrMeetingScheduled]
  )

  useEffect(() => {
    window.addEventListener('message', captureEvent, false)

    return () => {
      window.removeEventListener('message', captureEvent, false)
    }
  }, [captureEvent])

  const handleBackToDashboardButton = () => {
    if (chiliPiperError && isCallRequested && user) {
      submitBdrMeetingScheduled({
        data: {
          mail: user.email,
          ipRegion: visitorRegion,
          schedulingError: true,
        },
      })
    }
    goBack()
  }

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!isFormSubmitted && !(chiliPiperBlocked && bookingUrl)) {
        submitContactSalesEnterpriseClosure({})
        event.preventDefault()
        event.returnValue = '' // Chrome requires returnValue to be set
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isFormSubmitted, submitContactSalesEnterpriseClosure, chiliPiperBlocked, bookingUrl])

  return (
    <Box>
      <Stepper
        steps={steps}
        activeStep={activeStep}
        action='back to dashboard'
        actionToFunction={goBack}
        additionalActions={
          <Typography variant='body1' className={styles.topAction}>
            Billing question?{' '}
            <Link href={SUPPORT_PAGE_URL} style={{ textDecoration: 'none' }}>
              Contact support
            </Link>
          </Typography>
        }
      >
        <div className={styles.container}>
          <Paper sx={{ p: 2 }} className={styles.generalInfo}>
            {activeStep === Step.ContactSales ? (
              <>
                <Box pb={4}>
                  <Typography variant='h2'>Get in touch about Enterprise</Typography>
                  <Typography variant='body2' className={styles.subheader}>
                    Your request is sent directly to our Slack.
                  </Typography>
                </Box>
                <Box pb={3}>
                  <InputLabel htmlFor='apiCalls'>What is your estimated monthly API calls?</InputLabel>
                  <Select
                    fullWidth
                    displayEmpty
                    variant='outlined'
                    value={apiCalls}
                    onChange={(e) => {
                      setApiCalls(e.target.value)
                      updateMessage(e.target.value, messageText)
                    }}
                    inputProps={{
                      name: 'apiCalls',
                      id: 'apiCalls',
                    }}
                    sx={{ mt: 1, fontSize: '14px' }}
                    error={showErrorMessage && !apiCalls ? true : false}
                  >
                    <MenuItem disabled value=''>
                      <span className={styles.selectPlaceHolder}>Select a range of API calls</span>
                    </MenuItem>
                    {apiCallsOptions.map((option) => (
                      <MenuItem key={option.value} value={option.label} sx={{ fontSize: '14px' }}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {showErrorMessage && !apiCalls && <span className={styles.helperRed}>Please select an option.</span>}
                </Box>
                <Box pb={4}>
                  <InputLabel htmlFor='message'>Tell us about your project</InputLabel>
                  <TextFieldWithCounter
                    defaultValue={messageText}
                    placeholder='Tell us about your project, needs, or any questions you may have...'
                    errorMessage={
                      showErrorMessage && messageText.length < 1
                        ? 'Please provide details about your project.'
                        : undefined
                    }
                    maxSym={messageMaxLength}
                    onChange={(val) => updateMessage(apiCalls, val)}
                  />
                </Box>

                <span className={styles.requiredMessage}>All fields required</span>

                <div className={styles.buttonContainer}>
                  <Button className={clsx(styles.button, styles.buttonGrey)} variant='ghost' size='lg' onPress={goBack}>
                    Cancel
                  </Button>

                  <Button
                    isDisabled={isLoadingMeeting}
                    className={styles.button}
                    data-testid='confirm-cancel-feedback'
                    size='lg'
                    onPress={() =>
                      messageText.length < 1 || !apiCalls ? setShowErrorMessage(true) : submitEnterpriseRequest()
                    }
                  >
                    Submit form
                  </Button>
                </div>
              </>
            ) : (
              <>
                {chiliPiperError ? (
                  <Paper className={styles.chiliPiperError}>
                    <ErrorOutline className={styles.errorIcon} />
                    <Typography variant='h3' className={styles.unavailableMessage}>
                      Booking provider unavailable
                    </Typography>
                    <Typography variant='body2' className={styles.errorMessage}>
                      If you&apos;d still like a call, check the box below, and we&apos;ll reach out to you by email.
                    </Typography>
                    <FormControlLabel
                      sx={{ mr: 0 }}
                      componentsProps={{ typography: { variant: 'bodyS' } }}
                      label='Yes, I would like a dedicated call with a Fingerprint team member'
                      control={
                        <Checkbox checked={isCallRequested} onChange={(e) => setIsCallRequested(e.target.checked)} />
                      }
                    />
                  </Paper>
                ) : (
                  <>
                    <Box sx={{ mb: 4 }}>
                      <Typography className={styles.heading} variant='h2'>
                        <span className={styles.optionalText}>Optional:</span> Book a setup call
                      </Typography>
                      <Typography className={styles.subheader}>
                        Our sales team will be in touch, but feel free to pick a time that works for you.
                      </Typography>
                    </Box>
                    <div id='chiliModal' className={styles.chiliPiper} />
                  </>
                )}

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                  {chiliPiperScheduled || chiliPiperError ? (
                    <Button size='lg' onPress={handleBackToDashboardButton}>
                      Back to dashboard
                    </Button>
                  ) : (
                    <Button variant='outline' size='lg' onPress={goBack}>
                      Skip, back to dashboard
                    </Button>
                  )}
                </Box>
              </>
            )}
          </Paper>

          <div className={styles.previewContainer}>
            <Preview
              header='#bdr-meeting-alerts'
              tag='Preview'
              messages={slackPreviewMessages}
              botMessage={previewMessage}
              botAvatar={surveyBotAvatar}
              maxLength={100}
            />
          </div>
        </div>
      </Stepper>

      {(isLoadingVisitorData || isLoadingUser) && <FullpageLoader />}
    </Box>
  )
}
