import { Stack } from '@compass/components'
import { Typography } from '@mui/material'
import { ChartBarDecreasing } from 'lucide-react'

export function ChartError() {
  return (
    <Stack direction='column' className='w-full h-full m-auto items-center justify-center bg-gray-100 rounded-lg'>
      <ChartBarDecreasing className='size-4 text-gray-800' />
      <Typography variant='bodyS'>There was an error displaying the chart.</Typography>
    </Stack>
  )
}
export function ChartNoData() {
  return (
    <Stack direction='column' className='w-full h-full m-auto items-center justify-center bg-gray-100 rounded-lg'>
      <ChartBarDecreasing className='size-4 text-gray-800' />
      <Typography variant='bodyS'>There is not enough data to display this chart.</Typography>
    </Stack>
  )
}
