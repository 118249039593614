import { AccountDeleteSection } from 'features/accountDangerZone'
import { Header, Layout, MainColumn, SettingsLayout } from 'features/commonUI'

import { useDocumentTitle } from '../../hooks'
import { useUserContext } from '../../hooks/api/context'
import { PendingInvitations } from './components/PendingInvitations/PendingInvitations'
import { PersonalInfoForm } from './components/PersonalInfoForm'
import { ProfileSecurityForm } from './components/ProfileSecurityForm'
import { TimeForm } from './components/TimeForm'

export function ProfileSettingsPage() {
  useDocumentTitle('Profile settings')
  const { data: context } = useUserContext()

  return (
    <Layout>
      <Header title='Settings' />
      <MainColumn>
        <SettingsLayout>
          <PendingInvitations />
          <PersonalInfoForm />
          {context != null && !context.isSsoEnabled ? <ProfileSecurityForm /> : null}
          <TimeForm />
          <AccountDeleteSection />
        </SettingsLayout>
      </MainColumn>
    </Layout>
  )
}
