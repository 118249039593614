import { useQuery, useQueryClient } from '@tanstack/react-query'
import { User } from 'models'
import { useHistory } from 'react-router-dom'

import { useQueryParams } from '../queryParams'
import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'
import { useUpdateUserContext } from './context'

const usersCacheKey = ['users']
export const currentUserCacheKey = ['currentUser']

export function useUsers(enabled = true) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: usersCacheKey,
    queryFn: async () => extractData(withErrorHandling(buildRequest('usersGet'))),
    enabled,
  })
}

export function useUserUpdateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('userUpdate', {
    onSuccess: (user) => {
      queryClient.invalidateQueries(currentUserCacheKey)

      const prevData = queryClient.getQueryData<User[]>(usersCacheKey)
      const updatedIndex = prevData?.findIndex(({ id }) => id === user.id)

      if (prevData != null && updatedIndex != null && updatedIndex !== -1) {
        prevData[updatedIndex] = user
        queryClient.setQueryData<User[]>(usersCacheKey, prevData)
      } else {
        queryClient.invalidateQueries(usersCacheKey)
      }
    },
  })
}

export function useUserConsentUpdateMutation() {
  const history = useHistory()
  const { redirect_to } = useQueryParams()
  const updateUserContext = useUpdateUserContext()

  return useRequestMutation('userConsentUpdate', {
    onSuccess: () => {
      updateUserContext({ hasConsent: true })
      history.replace(redirect_to ?? '/')
    },
  })
}

export function useUserDeleteMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('userDelete', {
    onMutate: ({ params }) => {
      if (params) {
        const snapshot = queryClient.getQueryData<User[]>(usersCacheKey) || []
        const next = snapshot.filter(({ id }) => id !== params.userId)

        queryClient.setQueryData<User[]>(usersCacheKey, () => next)

        return () => queryClient.setQueryData<User[]>(usersCacheKey, snapshot)
      }

      return () => {}
    },
  })
}

export function useCurrentUser() {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler({ forceToast: true })

  return useQuery({
    queryKey: currentUserCacheKey,
    queryFn: () => extractData(withErrorHandling(buildRequest('currentUserGet'))),
  })
}
