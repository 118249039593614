import { SubscriptionType } from 'models'

import { formatNum, formatPriceInDollars } from '../../../helpers/format'

export const formatUsageValue = (value: number, subscriptionType?: SubscriptionType): string => {
  if (subscriptionType === SubscriptionType.TrialOnly) {
    return 'Unlimited during trial'
  }
  return formatNum(value)
}

export const formatOveragePrice = (value: number): string => {
  return formatPriceInDollars(value)
}

export const formatCost = (value: string): string => {
  const numericValue = parseFloat(value.replace('$', ''))
  return `$${numericValue.toFixed(2)}`
}
