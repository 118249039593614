import { Button, LinkButton, Stack } from '@compass/components'
import { Menu, MenuItem, MenuSeparator } from '@compass/Menu/Menu'
import { Popover } from '@compass/Popover'
import { InkeepCustomTrigger, InkeepCustomTriggerProps } from '@inkeep/uikit'
import { Skeleton } from '@mui/material'
import { DOCS_URL, SUPPORT_PAGE_URL } from 'const'
import { ProductFeedback } from 'features/commonUI'
import { useLastSeen, WHATS_NEW_AVAILABLE, WhatsNewModal } from 'features/whatsNew'
import { useAuth, useUiPreferences } from 'hooks'
import { useCurrentUser } from 'hooks/api/users'
import { useHasMadeRequestsPreviously } from 'hooks/useHasMadeRequestsPreviously'
import { Menu as MenuIcon, UserRound } from 'lucide-react'
import { ampli } from 'models/ampli'
import { Fragment, ReactNode, useCallback, useState } from 'react'
import { MenuTrigger } from 'react-aria-components'

import { useInkeepSettings } from '../../../integrations/components/Inkeep/Inkeep'
import { useExternalCommonItems } from '../Menus/ExternalMenuItems'
import { useFeedbackMenuCommonItems } from '../Menus/FeedbackMenuItems'
import { useMobileMenuCommonItems } from '../Menus/MobileMenuItems'
import { useProfileMenuCommonItems, useProfileNotifications } from '../Menus/ProfileMenuItems'
import { useAccountMenuCommonItems } from '../Menus/SettingsMenuItems'
import { useLogoutCommonItems } from '../Menus/SignOutMenuItems'
import { ProductFeedbackDialog } from '../ProductFeedback/ProductFeedback'
import styles from './Header.module.scss'

interface HeaderProps {
  title?: string
  info?: ReactNode
}

export function Header({ title, info }: HeaderProps) {
  const { updateUiPreferences } = useUiPreferences()
  const [isAskAiOpen, setIsAskAiOpen] = useState(false)
  const { baseSettings, aiChatSettings, searchSettings, modalSettings } = useInkeepSettings()
  const { accessTokenPayload } = useAuth()
  const userId = accessTokenPayload?.id

  const handleClose = useCallback(() => {
    setIsAskAiOpen(false)
  }, [])

  const inkeepCustomTriggerProps: InkeepCustomTriggerProps = {
    isOpen: isAskAiOpen,
    onClose: handleClose,
    baseSettings,
    aiChatSettings,
    searchSettings,
    modalSettings,
  }

  const [_, setLastSeen] = useLastSeen('whats-new-modal')
  const { hasMadeRequests, isLoading: isLoadingHasMadeRequests } = useHasMadeRequestsPreviously({
    includeCanceled: true,
  })

  return (
    <PureHeader
      onMenuClick={() => updateUiPreferences({ sidebar: { isOpened: true } })}
      title={title}
      info={info}
      extraActions={
        <>
          <ProductFeedback
            triggerComponent={Button}
            triggerProps={{
              variant: 'outline',
              tone: 'background2',
              alt: true,
              children: <>Share feedback</>,
            }}
          />
          <Stack gap={2}>
            <Button
              variant='ghost'
              tone='background2'
              alt
              onPress={() => {
                setIsAskAiOpen(true)
                ampli.identify(userId, { usedAppAskAI: true })
              }}
            >
              <span>Ask AI</span>
            </Button>
            {WHATS_NEW_AVAILABLE && !isLoadingHasMadeRequests && hasMadeRequests ? (
              <Button
                variant='ghost'
                tone='background2'
                alt
                onPress={() => {
                  setLastSeen(null)
                }}
              >
                <span>What&apos;s new</span>
                {/* {WHATS_NEW_IS_RECENT ? <Badge size='sm'>New</Badge> : null}{' '} */}
              </Button>
            ) : null}
            <LinkButton href={DOCS_URL} target='_blank' variant='ghost' tone='background2' alt>
              Docs
            </LinkButton>
            <LinkButton
              href={SUPPORT_PAGE_URL}
              target='_blank'
              variant='ghost'
              tone='background2'
              alt
              onPress={() => ampli.contactSupportClicked({ source: 'header' })}
            >
              Support
            </LinkButton>
          </Stack>
          <InkeepCustomTrigger {...inkeepCustomTriggerProps} />
        </>
      }
    />
  )
}

interface PureHeaderProps extends HeaderProps {
  onMenuClick: () => unknown
  extraActions?: ReactNode
}

export function PureHeader({ title, info, onMenuClick, extraActions }: PureHeaderProps) {
  return (
    <header className='sticky top-0 z-20 w-full my-0 mx-auto bg-gray-100 shadow-[0_1px_0_hsl(var(--fpds-color-gray-3))]'>
      <div className={styles.container}>
        <Button isIcon variant='ghost' aria-label='Open menu' className={styles.mobileMenuButton} onPress={onMenuClick}>
          <MenuIcon />
        </Button>

        <div className={styles.heading}>
          <h1 className='text-base text-gray-800 font-medium'>{title}</h1>
          {info}
        </div>

        <div className={styles.desktopButtons}>{extraActions}</div>
        <ProfileMenu />
      </div>
      <WhatsNewModal />
    </header>
  )
}

function ProfileDetails() {
  const { data: currentUser, isLoading } = useCurrentUser()

  if (isLoading) {
    return (
      <Stack direction='column' className='h-12 align-auto px-3 pt-2'>
        <Skeleton height={20} className='w-2/4' />
        <Skeleton height={18} className='w-3/4' />
      </Stack>
    )
  } else {
    return currentUser?.name != null ? (
      <Stack direction='column' className='px-3 pt-2 text-base'>
        <span className='sr-only'>
          Logged in as {currentUser.name}. Your account email is {currentUser.email}.
        </span>
        <p className='text-gray-1000 font-medium truncate' title={currentUser.name}>
          {currentUser.name}
        </p>
        <p className='text-[13px] text-gray-800 truncate' title={currentUser.email}>
          {currentUser.email}
        </p>
      </Stack>
    ) : null
  }
}

function ProfileMenu() {
  const [isFeedbackDialogOpen, setFeedbackDialogOpen] = useState(false)
  const [isAskAiOpen, setIsAskAiOpen] = useState(false)
  const { baseSettings, aiChatSettings, searchSettings, modalSettings } = useInkeepSettings()
  const { accessTokenPayload } = useAuth()
  const userId = accessTokenPayload?.id

  const handleClose = useCallback(() => {
    setIsAskAiOpen(false)
  }, [])

  const inkeepCustomTriggerProps: InkeepCustomTriggerProps = {
    isOpen: isAskAiOpen,
    onClose: handleClose,
    baseSettings,
    aiChatSettings,
    searchSettings,
    modalSettings,
  }

  const profileMenuCommonItems = useProfileMenuCommonItems()
  const accountMenuCommonItems = useAccountMenuCommonItems()
  const externalMenuCommonItems = useExternalCommonItems()
  const feedbackMenuCommonItems = useFeedbackMenuCommonItems({ onFeedbackClicked: () => setFeedbackDialogOpen(true) })
  const mobileMenuCommonItems = useMobileMenuCommonItems({
    onAskAiClicked: () => {
      setIsAskAiOpen(true)
      ampli.identify(userId, { usedAppAskAI: true })
    },
  })
  const logoutCommonItems = useLogoutCommonItems()

  const { notificationCount } = useProfileNotifications()

  const sections = [
    profileMenuCommonItems,
    accountMenuCommonItems,
    externalMenuCommonItems,
    ...(feedbackMenuCommonItems ? [feedbackMenuCommonItems] : []),
    ...(mobileMenuCommonItems ? [mobileMenuCommonItems] : []),
    logoutCommonItems,
  ]

  const hasNotifications = notificationCount > 0

  return (
    <>
      <ProductFeedbackDialog dialogOpen={isFeedbackDialogOpen} setDialogOpen={setFeedbackDialogOpen} />

      <MenuTrigger>
        <Button
          variant='none'
          isIcon
          aria-label='Profile menu'
          className='bg-orange-300 border border-orange-400 rounded text-orange-900 font-medium shadow-avatar'
        >
          <UserInitialsOrIcon />
          {hasNotifications && <NotificationIndicator />}
        </Button>
        <Popover placement='bottom end' className='min-w-[150px] w-56'>
          <ProfileDetails />
          <Menu>
            {sections.map((section, index) => (
              <Fragment key={index}>
                {section.map((item) => {
                  const Component = item.component
                  return (
                    <MenuItem
                      key={item.key}
                      id={item.key}
                      suffix={item.icon}
                      textValue={item.label}
                      href={item.link}
                      target={item.link ? item.target : undefined}
                      onAction={() => item.onAction?.()}
                      variant={item.destructive ? 'destructive' : undefined}
                      className={item.label != null ? item.className : undefined}
                      style={item.style}
                      isDisabled={item.disabled}
                    >
                      {Component ? <Component /> : null}
                    </MenuItem>
                  )
                })}
                {index < sections.length - 1 ? <MenuSeparator /> : null}
              </Fragment>
            ))}
          </Menu>
        </Popover>
      </MenuTrigger>
      <InkeepCustomTrigger {...inkeepCustomTriggerProps} />
    </>
  )
}

function UserInitialsOrIcon() {
  const { data, isLoading } = useCurrentUser()

  if (isLoading) {
    return null
  }

  if (!data?.name) {
    return <UserRound />
  }

  const nameParts = data.name.split(' ')
  const firstLetter = nameParts.shift()?.charAt(0)?.toUpperCase()
  const lastLetter = nameParts.pop()?.charAt(0)?.toUpperCase()

  return (
    <>
      {firstLetter ?? ''}
      {lastLetter ?? ''}
    </>
  )
}

function NotificationIndicator() {
  return (
    <span
      className='absolute -top-1 -right-1 size-2 rounded-full bg-red-700 ring-2 ring-gray-100'
      aria-label='You have pending actions'
    />
  )
}
