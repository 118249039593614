import { ReactNode } from 'react'
import {
  composeRenderProps,
  ToggleButton as AriaToggleButton,
  ToggleButtonProps as AriaToggleButtonProps,
  TooltipTrigger,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import { ButtonExtraProps, buttonStyles } from './Button/Button'
import { Tooltip } from './Tooltip'

export const toggleButtonStyles = tv({
  extend: buttonStyles,
  compoundVariants: [
    {
      variant: 'ghost',
      tone: 'background1',
      className: 'selected:bg-gray-300',
    },
    {
      variant: 'ghost',
      tone: 'background2',
      className: 'selected:bg-gray-400',
    },
  ],
})

export type ToggleButtonProps = AriaToggleButtonProps &
  ButtonExtraProps & {
    children: ReactNode
  }

export function ToggleButton({
  children,
  variant = 'ghost',
  tone = 'background1',
  alt,
  size = 'default',
  isIcon = true,
  isLoading,
  tooltip,
  ...props
}: ToggleButtonProps) {
  const styleProps = { variant, tone, alt, size, isIcon, isLoading } as const

  const button = (
    <AriaToggleButton
      {...props}
      isDisabled={props.isDisabled || isLoading}
      className={composeRenderProps(props.className, (className, renderProps) =>
        toggleButtonStyles({
          ...renderProps,
          ...styleProps,
          className,
        })
      )}
    >
      {children}
    </AriaToggleButton>
  )

  if (tooltip) {
    return (
      <TooltipTrigger delay={500}>
        {button}
        <Tooltip>{tooltip}</Tooltip>
      </TooltipTrigger>
    )
  }

  return button
}
