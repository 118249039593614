import { BotdFilterFormType } from 'components/Filter/Botd/BotdFilter'
import { CounterQueryType } from 'const'
import {
  DateRangeContextProvider,
  Header,
  MainColumn,
  smartSignalsNavItems,
  TabNavigation,
  useDateRangeContext,
  useDefaultPredefinedRanges,
} from 'features/commonUI'
import { useCurrentSubscription, useCurrentSubscriptionData } from 'features/subscription'
import { calculateGranularityFromRange, CustomDateRange, PredefinedRange } from 'helpers/dateRange'
import { isProductEnabled } from 'helpers/subscription'
import { ValueOf } from 'helpers/types'
import { useDocumentTitle } from 'hooks'
import { useUsageCounters } from 'hooks/api/usage_counters'
import { useCurrentUser } from 'hooks/api/users'
import { BotdVisitFilter, usePaginatedBotdVisits } from 'hooks/botdVisits'
import { Settings } from 'luxon'
import { AppProduct, BotdVisit, SortingOptions } from 'models'
import { ampli } from 'models/ampli'
import { useEffect, useState } from 'react'

import BotDIntroduction from './components/BotDIntroduction/BotDIntroduction'
import { BotdOverview } from './components/BotdOverview/BotdOverview'

export function BotdOverviewPage() {
  const [tzReady, setTzReady] = useState(false)
  // Prefetch the current user for timezone.
  const { isLoading, data: currentUser } = useCurrentUser()
  useEffect(() => {
    if (currentUser?.timezone && !isLoading) {
      Settings.defaultZone = currentUser.timezone
      setTzReady(true)
    }
  }, [currentUser?.timezone, isLoading])

  return tzReady ? (
    <DateRangeContextProvider>
      <Page />
    </DateRangeContextProvider>
  ) : (
    <>
      <Header title='Smart Signals' />
      <TabNavigation items={smartSignalsNavItems} />
    </>
  )
}

function Page() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { subscription, isLoading: isLoadingSubscription } = useCurrentSubscriptionData()

  const { data: currentUser } = useCurrentUser()
  const { ranges } = useDefaultPredefinedRanges()
  const isBotdDisabled = subscriptionId && !isLoadingSubscription && !isProductEnabled(subscription, AppProduct.Botd)
  useDocumentTitle(isBotdDisabled ? 'Introducing Bot Detection' : 'Bot Detection')

  const { dateRange, setDateRange } = useDateRangeContext()
  const granularity = dateRange ? calculateGranularityFromRange(dateRange, true) : undefined

  useEffect(() => {
    if (!dateRange && setDateRange) {
      setDateRange(ranges.today)
    }
  }, [dateRange, ranges.today, setDateRange])

  const [tableOrderParams, setTableOrderParams] = useState<SortingOptions<BotdVisit>>({
    sortBy: 'timestamp',
    order: 'desc',
  })
  const [filter, setFilter] = useState<BotdVisitFilter | undefined>()

  const paginatedVisits = usePaginatedBotdVisits({
    subscriptionId,
    from: dateRange?.startDate?.valueOf(),
    before: dateRange?.endDate?.valueOf(),
    reverse: tableOrderParams.sortBy === 'timestamp' && tableOrderParams.order === 'asc',
    // Botd visits can only be filtered by requestId.
    requestId: filter?.value,
  })

  const {
    data: counters,
    isLoading: isLoadingCounters,
    error: countersError,
  } = useUsageCounters({
    subscriptionId,
    // for usage counters handle, dates should be in user timezone
    from: dateRange?.startDate,
    to: dateRange?.endDate,
    queryType: CounterQueryType.Botd,
    period: granularity,
    timezone: currentUser?.timezone,
    enabled: !isBotdDisabled,
    keepPreviousData: true,
  })

  const handleApplyDateRange = (range: CustomDateRange | PredefinedRange) => {
    // Filters cannot be used together with date ranges.
    setFilter(undefined)
    setDateRange?.(range)
  }

  const handleFilter = (key?: keyof BotdFilterFormType, value?: ValueOf<BotdFilterFormType>) => {
    setFilter(key && value ? { key, value } : undefined)
  }

  if (isBotdDisabled) {
    return <BotDIntroduction />
  }

  return (
    <>
      <Header title='Smart Signals' />
      <TabNavigation items={smartSignalsNavItems} />
      <MainColumn className='relative'>
        <BotdOverview
          counters={counters}
          tableProps={{
            ...paginatedVisits,
            ...tableOrderParams,
            timezone: currentUser?.timezone,
            onSort: (sortBy, order) => setTableOrderParams({ sortBy, order }),
            onRequestNextEntries: () => {
              ampli.botDPaginationClicked({ direction: 'forward' })
              paginatedVisits.onRequestNextEntries()
            },
            onRequestPreviousEntries: () => {
              ampli.botDPaginationClicked({ direction: 'backward' })
              paginatedVisits.onRequestPreviousEntries()
            },
          }}
          dateRange={dateRange}
          granularity={granularity}
          timezone={currentUser?.timezone}
          onApplyRange={handleApplyDateRange}
          appliedFilter={filter}
          onFilter={handleFilter}
          subscriptionId={subscriptionId}
          error={countersError}
          isLoading={isLoadingSubscription}
          isLoadingCounters={isLoadingCounters}
        />
      </MainColumn>
    </>
  )
}
