import { Badge } from '@compass/Badge'
import { AppRoute, buildRoute } from 'appRoutes'
import { useMyInvitations } from 'hooks/api/user_invitations'
import { UserRoundPen } from 'lucide-react'

import { CommonMenuItem } from './CommonMenuSection'

export const useProfileMenuCommonItems: () => CommonMenuItem[] = () => {
  const { notificationCount } = useProfileNotifications()

  const icon =
    notificationCount > 0 ? (
      <Badge tone='danger' variant='subtle'>
        {notificationCount > 1 ? `${notificationCount} pending actions` : '1 pending action'}
      </Badge>
    ) : (
      <UserRoundPen />
    )

  return [
    {
      key: 'my-profile',
      label: 'My profile',
      icon,
      link: buildRoute(AppRoute.ProfileSettings),
      associatedRoutes: [AppRoute.ProfileSettings],
    },
  ]
}

export function useProfileNotifications() {
  const { data } = useMyInvitations()

  return {
    notificationCount: data?.length ?? 0,
  }
}
