import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

/**
 * Locks the page and displays an alert when the user tries to navigate away from the page.
 * @param alertText - The text to display in the alert when the user tries to navigate away from the page.
 */
export function useLockPageRedirect(alertText: string) {
  const history = useHistory()
  useEffect(() => {
    // prevents reloading before confirmation
    // fully blocking reload isn't possible.
    const preventReload = (e: Event) => {
      e.preventDefault()
      return false
    }
    window.addEventListener('beforeunload', preventReload)

    // prevents navigation
    const unblock = history.block((_loc) => {
      window.alert(alertText)
      return false
    })

    return () => {
      unblock()
      window.removeEventListener('beforeunload', preventReload)
    }
  }, [history, alertText])
}
