import { formatNum, formatPriceInDollars } from '../../../helpers/format'
import { Discount, SubscriptionType } from '../../../models'
import { formatOveragePrice } from '../helpers/format'
import { UsageEntry } from '../types'

export const useUsageCalculations = (
  entries: UsageEntry[],
  subscriptionType?: SubscriptionType,
  appliedDiscount?: Discount
) => {
  const defaultReturn = {
    usageCostInCents: 0,
    planPriceInCents: 0,
    totalCostInCents: 0,
    finalCostInCents: 0,
    discountAmount: 0,
    tooltipContent: null,
  }

  if (subscriptionType !== SubscriptionType.Paid || entries.length === 0) {
    return defaultReturn
  }

  const planPriceInCents = Math.round(entries[0].cost ? parseFloat(entries[0].cost.replace('$', '')) * 100 : 0)
  const totalCurrentUsage = entries.reduce((sum, entry) => sum + entry.currentUsage, 0)
  const includedUsage = entries[0].includedWithPlan
  const overagePrice = entries[0].overagePrice || 0
  const overageUsage = Math.max(0, totalCurrentUsage - includedUsage)
  const usageCostInCents = Math.round(overageUsage * (overagePrice / 100) * 100)

  const totalCostInCents = Math.round(planPriceInCents + usageCostInCents)
  const discountAmount = Math.round(
    appliedDiscount?.coupon?.percentOff ? totalCostInCents * (appliedDiscount.coupon.percentOff / 100) : 0
  )
  const finalCostInCents = Math.round(totalCostInCents - discountAmount)

  const tooltipContent = (
    <>
      {totalCurrentUsage < includedUsage ? (
        <>
          {formatNum(includedUsage)} (included w/ plan) - {formatNum(totalCurrentUsage)} (current usage) ={' '}
          {formatNum(includedUsage - totalCurrentUsage)} free remaining
        </>
      ) : (
        <>
          {formatNum(totalCurrentUsage)} (current usage) - {formatNum(includedUsage)} (included w/ plan) ={' '}
          {formatNum(overageUsage)}
          <br />
          {formatNum(overageUsage)} * {formatOveragePrice(overagePrice)} (overage price) ={' '}
          {formatPriceInDollars(usageCostInCents)}
        </>
      )}
    </>
  )

  return {
    usageCostInCents,
    planPriceInCents,
    totalCostInCents,
    finalCostInCents,
    discountAmount,
    tooltipContent,
  }
}
