import { CustomDateRange, PredefinedRange } from 'helpers/dateRange'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

export function useRpsTooltipData(
  tooltipIndex: number | undefined,
  serie: Array<{
    timestamp: DateTime
    peak: number
    average: number
    throttled: number
    throttledPercentage: number
  }>,
  dateRange: CustomDateRange | PredefinedRange | undefined
) {
  return useMemo(() => {
    if (tooltipIndex == null) {
      return null
    }

    const value = serie?.[tooltipIndex]
    const periodStart = value?.timestamp ?? dateRange?.startDate
    const periodEnd = serie?.[tooltipIndex + 1]?.timestamp ?? dateRange?.endDate

    if (value == null) {
      return null
    }

    return {
      periodStart,
      periodEnd,
      peak: value.peak,
      average: value.average,
      throttled: value.throttled,
      throttledPercentage: value.throttledPercentage,
    }
  }, [dateRange?.endDate, dateRange?.startDate, serie, tooltipIndex])
}
