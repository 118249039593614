import { SUBSCRIPTION_PLAN_PRICES, SubscriptionDisplayPlan } from 'const/content'
import { formatPriceInDollars } from 'helpers/format'
import { ExpandedSubscription } from 'models'

interface GetEntriesParams {
  subscription: ExpandedSubscription
  useNewBillingQuota: boolean
  usageLimit: number | undefined
  isSubscriptionPlan: boolean
  getOveragePrice: (subscription: ExpandedSubscription) => number | undefined
  displayPlan: SubscriptionDisplayPlan
}

export function getEntries({
  subscription,
  useNewBillingQuota,
  usageLimit = 0,
  isSubscriptionPlan,
  getOveragePrice,
  displayPlan,
}: GetEntriesParams) {
  const planPriceInCents = SUBSCRIPTION_PLAN_PRICES[displayPlan] as number

  if (useNewBillingQuota && subscription.billingQuota) {
    return [
      {
        platform: 'Web',
        includedWithPlan: usageLimit,
        overagePrice: getOveragePrice(subscription),
        currentUsage: subscription.billingQuota.byClientType.js.billed,
        cost: isSubscriptionPlan ? formatPriceInDollars(planPriceInCents) : undefined,
      },
      {
        platform: 'Android',
        includedWithPlan: usageLimit,
        overagePrice: getOveragePrice(subscription),
        currentUsage: subscription.billingQuota.byClientType.android.billed,
        cost: isSubscriptionPlan ? formatPriceInDollars(planPriceInCents) : undefined,
      },
      {
        platform: 'iOS',
        includedWithPlan: usageLimit,
        overagePrice: getOveragePrice(subscription),
        currentUsage: subscription.billingQuota.byClientType.ios.billed,
        cost: isSubscriptionPlan ? formatPriceInDollars(planPriceInCents) : undefined,
      },
    ]
  }

  return [
    {
      platform: 'Web',
      includedWithPlan: usageLimit,
      overagePrice: getOveragePrice(subscription),
      currentUsage: subscription.usageQuota?.billed ?? 0,
      cost: isSubscriptionPlan ? formatPriceInDollars(planPriceInCents) : undefined,
    },
  ]
}
