import { CounterQueryType } from 'const'
import { CustomDateRange, PredefinedRange } from 'helpers/dateRange'
import { useUsageCounters } from 'hooks/api/usage_counters'
import groupBy from 'lodash/groupBy'
import valuesOf from 'lodash/values'
import { DateTime } from 'luxon'
import { UsageCounterPeriod, User } from 'models'
import { useMemo } from 'react'

export type Value = { timestamp: DateTime; throttled?: number; restricted?: number }

export function useFailedApiCallsComparison(
  subscriptionId?: string,
  currentUser?: User,
  dateRange?: CustomDateRange | PredefinedRange,
  granularity?: UsageCounterPeriod
) {
  const commonProps = {
    subscriptionId,
    // for usage counters handle, dates should be in user timezone
    from: dateRange?.startDate,
    to: dateRange?.endDate,
    queryType: CounterQueryType.IdentificationUniqueVisitors,
    period: granularity,
    timezone: currentUser?.timezone,
    enabled: currentUser != null,
  } satisfies Partial<Parameters<typeof useUsageCounters>[0]>

  const {
    data: usageCountersData,
    isLoading: usageCountersLoading,
    error: usageCountersError,
  } = useUsageCounters({
    ...commonProps,
    from: dateRange?.startDate,
    to: dateRange?.endDate,
  })

  const values: Value[] = useMemo(() => {
    const throttled =
      usageCountersData?.throttled_calls?.map(({ timestamp, value }) => ({
        timestamp: DateTime.fromISO(timestamp?.split(/[+Z]/).shift() + '', {
          zone: currentUser?.timezone ?? 'UTC',
        }),
        throttled: value,
      })) ?? []

    const restricted =
      usageCountersData?.restricted_calls?.map(({ timestamp, value }) => ({
        timestamp: DateTime.fromISO(timestamp?.split(/[+Z]/).shift() + '', {
          zone: currentUser?.timezone ?? 'UTC',
        }),
        restricted: value,
      })) ?? []

    const valuesByTimestamp = groupBy([...throttled, ...restricted], (v) => v.timestamp.valueOf())
    const result = valuesOf(valuesByTimestamp).map((list) => Object.assign({}, ...list))
    return result.sort((a, b) => a.timestamp.valueOf() - b.timestamp.valueOf())
  }, [currentUser?.timezone, usageCountersData?.restricted_calls, usageCountersData?.throttled_calls])

  const [xDomain, yDomain] = useMemo(() => {
    const timestamps = values.map((p) => p.timestamp.valueOf())
    const everyValue = values.flatMap((p) => [p.throttled, p.restricted]).filter(Boolean) as number[]

    return [
      [Math.min(...timestamps), Math.max(...timestamps)],
      [0, Math.max(...everyValue, 10)],
    ] satisfies Array<[number, number]>
  }, [values])

  return useMemo(
    () => ({
      isLoading: usageCountersLoading,
      error: usageCountersError,
      values,
      xDomain,
      yDomain,
    }),
    [usageCountersError, usageCountersLoading, values, xDomain, yDomain]
  )
}
