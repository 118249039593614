'use client'
import { Button, Stack } from '@compass/components'
import { X } from 'lucide-react'
import {
  composeRenderProps,
  Dialog as AriaDialog,
  DialogProps as AriaDialogProps,
  DialogTrigger as AriaDialogTrigger,
  Heading as AriaHeading,
  HeadingProps as AriaHeadingProps,
  Modal as AriaModal,
  ModalOverlay as AriaModalOverlay,
  ModalOverlayProps as AriaModalOverlayProps,
} from 'react-aria-components'
import { twMerge } from 'tailwind-merge'
import { tv, type VariantProps } from 'tailwind-variants'

const Dialog = AriaDialog

const overlayStyles = tv({
  base: 'fixed top-0 left-0 w-full h-[--visual-viewport-height] isolate z-[1201] flex items-center justify-center p-4 text-center bg-black/70',
  variants: {
    isEntering: {
      true: 'animate-in fade-in duration-300 ease-in-out-cubic',
    },
    isExiting: {
      true: 'animate-out fade-out duration-300 ease-in-out-quart',
    },
  },
})

const modalStyles = tv({
  base: 'relative w-full max-w-xl max-h-[min(576px,90dvh)] overflow-hidden flex flex-col rounded-xl bg-gray-100 dark:bg-gray-800/70 dark:backdrop-saturate-200 forced-colors:bg-[Canvas] text-left align-middle text-gray-1000 dark:text-gray-300 shadow-600 bg-clip-padding border border-black/10 dark:border-white/10',
  variants: {
    isEntering: {
      true: 'animate-in fade-in motion-safe:slide-in-from-top-1/4 ease-in-out-cubic duration-300',
    },
    isExiting: {
      true: 'animate-out fade-out motion-safe:slide-out-to-top-1/4 ease-in-out-quart duration-300',
    },
  },
})

const dialogStyles = tv({
  base: ['overflow-y-auto', 'flex flex-col', 'h-full', 'outline outline-0 outline-none'],
})

// Temporary solution to handle cases where <DialogContent/> contains a wrapping container e.g. <form> tag.
// Wrapping tags like forms must use contentStyles to maintain desired, consistent scroll behavior.
export const contentStyles = tv({
  base: 'overflow-y-auto flex flex-col h-full',
})

const headerStyles = tv({
  base: 'flex flex-shrink-0 items-center h-11 pl-5',
})

const sheetVariants = tv({
  variants: {
    isEntering: {
      true: 'animate-in fade-in duration-300 ease-in-out-cubic',
    },
    isExiting: {
      true: 'animate-out fade-out duration-300 ease-in-out-quart',
    },
    side: {
      top: 'inset-x-0 top-0 border-b data-[entering]:slide-in-from-top data-[exiting]:slide-out-to-top',
      bottom: 'inset-x-0 bottom-0 border-t data-[entering]:slide-in-from-bottom data-[exiting]:slide-out-to-bottom',
      left: 'inset-y-0 left-0 h-full w-3/4 border-r data-[entering]:slide-in-from-left data-[exiting]:slide-out-to-left sm:max-w-sm',
      right:
        'inset-y-0 right-0 h-full w-3/4  border-l data-[entering]:slide-in-from-right data-[exiting]:slide-out-to-right sm:max-w-sm',
    },
  },
})

const DialogTrigger = AriaDialogTrigger

const ModalOverlay = ({ className, isDismissable = true, ...props }: AriaModalOverlayProps) => (
  <AriaModalOverlay isDismissable={isDismissable} className={overlayStyles} {...props} />
)

interface DialogContentProps
  extends Omit<React.ComponentProps<typeof AriaModal>, 'children'>,
    VariantProps<typeof sheetVariants> {
  children?: AriaDialogProps['children']
  role?: AriaDialogProps['role']
  closeButton?: boolean
}

const DialogContent = ({ className, children, side, role, closeButton = true, ...props }: DialogContentProps) => (
  <AriaModal
    className={composeRenderProps(className, (cn, renderProps) =>
      twMerge(
        modalStyles({
          ...renderProps,
          className: cn,
        }),
        side && sheetVariants({ side, className: 'h-full p-6' })
      )
    )}
    {...props}
  >
    <AriaDialog role={role} className={dialogStyles()}>
      {composeRenderProps(children, (child, renderProps) => (
        <>
          {child}
          {closeButton && (
            <Button
              onPress={renderProps.close}
              variant='ghost'
              tone='background2'
              alt
              isIcon
              className='absolute top-2 right-2'
              type='button'
            >
              <X />
            </Button>
          )}
        </>
      ))}
    </AriaDialog>
  </AriaModal>
)

const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={twMerge(headerStyles(), className)} {...props} />
)

const DialogBody = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <Stack
    gap={4}
    direction='column'
    className={twMerge(
      'relative z-10 m-1 px-8 py-7 overflow-y-auto rounded-t-lg bg-white border border-b-0',
      className
    )}
    {...props}
  />
)

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <Stack
    className={twMerge(
      'sticky bottom-0 justify-between mx-1 -mt-1 mb-1 p-4 bg-gray-100 border rounded-b-lg',
      className
    )}
    {...props}
  />
)

const DialogTitle = ({ className, ...props }: AriaHeadingProps) => (
  <AriaHeading slot='title' className={twMerge('mt-1.5 text-xl font-medium', className)} {...props} />
)

const DialogDescription = ({ className, ...props }: React.HTMLAttributes<HTMLParagraphElement>) => (
  <p className={twMerge('flex flex-col space-y-1.5 text-center sm:text-left', className)} {...props} />
)

export {
  Dialog,
  ModalOverlay,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogTitle,
}
export type { DialogContentProps }
