import { BillingType, SubscriptionType } from 'models'

import { COLUMN_HEADERS, UsageTableColumn } from '../types'

export const getTableColumns = (
  subscriptionType?: SubscriptionType,
  billingType?: BillingType,
  isRelatedVisitors?: boolean,
  entries?: Array<any>
): UsageTableColumn[] => {
  const hasMultipleEntries = entries && entries.length > 1
  const platformHeader = hasMultipleEntries ? COLUMN_HEADERS.PLATFORM : ''

  if (isRelatedVisitors) {
    return [
      { key: 'platform', header: platformHeader },
      { key: 'currentUsage', header: COLUMN_HEADERS.CURRENT_USAGE(billingType) },
      { key: 'includedWithPlan', header: COLUMN_HEADERS.INCLUDED_PLAN(billingType) },
    ]
  }

  let columns: UsageTableColumn[]
  switch (subscriptionType) {
    case SubscriptionType.Prepaid:
    case SubscriptionType.ProofOfConcept:
      columns = [
        { key: 'platform', header: platformHeader },
        { key: 'currentUsage', header: COLUMN_HEADERS.CURRENT_USAGE(billingType) },
        { key: 'includedWithPlan', header: COLUMN_HEADERS.INCLUDED_CONTRACT(billingType) },
      ]
      break
    case SubscriptionType.Paid:
      columns = [
        { key: 'platform', header: platformHeader },
        { key: 'overagePrice', header: COLUMN_HEADERS.OVERAGE_PRICE },
        { key: 'currentUsage', header: COLUMN_HEADERS.CURRENT_USAGE(billingType) },
        { key: 'includedWithPlan', header: COLUMN_HEADERS.INCLUDED_PLAN(billingType) },
        { key: 'cost', header: COLUMN_HEADERS.COST },
      ]
      break
    default:
      columns = [
        { key: 'platform', header: platformHeader },
        { key: 'currentUsage', header: COLUMN_HEADERS.CURRENT_USAGE(billingType) },
        { key: 'includedWithPlan', header: COLUMN_HEADERS.INCLUDED_PLAN(billingType) },
      ]
  }

  return columns
}
