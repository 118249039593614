import { Skeleton, Table } from '@mui/material'
import { curveMonotoneX, curveStep } from '@visx/curve'
import { Group } from '@visx/group'
import { Threshold } from '@visx/threshold'
import { TableBody, TableBodyData, TableCell, TableHead, TableRow } from 'components/Table/Table'
import { useDateRangeContext } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { formatNum, formatNumShort, formatPercent } from 'helpers/format'
import { useCurrentUser } from 'hooks/api/users'
import { useTimeline } from 'hooks/api/useTimeline'
import { useSubscriptionStatsQuery } from 'hooks/subscriptionStats'
import { DateTime } from 'luxon'
import { SubscriptionStatistic, SubscriptionStatisticValue, UsageCounterPeriod } from 'models'
import { useMemo } from 'react'

import { ChartError, ChartNoData } from '../components/ChartError'
import { ChartGraph } from '../components/ChartGraph'
import { ChartHeader } from '../components/ChartHeader'
import { ChartLayout } from '../components/ChartLayout'
import {
  ChartLegend,
  ChartLegendItem,
  HighlightMarker,
  OrangeMarker,
  PatternHighlight,
  PurpleMarker,
} from '../components/ChartLegend'
import { ChartLoading } from '../components/ChartLoading'
import { ChartTitle } from '../components/ChartTitle'
import { ChartMainValue, ChartValue, ChartValueComparison } from '../components/ChartValue'
import { CircleMark } from '../components/CircleMark'
import { LineWithShadow } from '../components/LineWithShadow'
import { TooltipContainer, TooltipContent, TooltipHeader } from '../components/Tooltip'
import { useRpsTooltipData } from '../hooks/useRpsTooltipData'
import { useTimelineIndexer } from '../hooks/useTimelineIndexer'
import { InsightsChartProps } from '../InsightsChartProps'
import { ResponsiveVisXChart } from '../VisXChart'
import { formatChartTimestamp } from './dateFormat'

type Value = {
  timestamp: DateTime
  peak: number
  average: number
  throttled: number
  throttledPercentage: number
}

export function RpsContent({ action, granularity, showTable }: InsightsChartProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: currentUser } = useCurrentUser()
  const { dateRange } = useDateRangeContext()

  const {
    data: metrics,
    isLoading,
    error,
  } = useTimeline(subscriptionId, ['rps'], dateRange?.startDate, dateRange?.endDate, granularity)

  const serie = useMemo(
    () =>
      metrics?.rps?.map(({ timestamp, peak, average, throttled, throttledPercentage }) => ({
        timestamp: DateTime.fromISO(timestamp),
        peak,
        average,
        throttled: parseInt(throttled),
        throttledPercentage,
      })) ?? [],
    [metrics?.rps]
  )

  const { handlePointer, tooltipIndex, position } = useTimelineIndexer(serie, (p) => p?.timestamp?.valueOf())
  const tooltipData = useRpsTooltipData(tooltipIndex, serie, dateRange)

  const [xDomain, yDomain] = useMemo(() => {
    const timestamps = serie?.map((p) => p.timestamp.valueOf() as number) ?? []
    const everyValue = serie?.flatMap((p) => [p.peak, p.average]) ?? []

    return [
      [Math.min(...timestamps), Math.max(...timestamps)],
      [Math.min(...everyValue), Math.max(...everyValue)],
    ] satisfies Array<[number, number]>
  }, [serie])

  return (
    <ChartLayout>
      <Header action={action} />
      {showTable ? (
        <ChartTable isLoading={isLoading} values={serie} granularity={granularity} />
      ) : (
        <>
          <Legend />
          <ChartGraph>
            {isLoading ? <ChartLoading /> : null}
            {error ? <ChartError /> : null}
            {!isLoading && !error && serie?.length === 0 ? <ChartNoData /> : null}
            {isLoading || error || serie?.length === 0 ? null : (
              <ResponsiveVisXChart
                timezone={currentUser?.timezone}
                xDomain={xDomain}
                yDomain={yDomain}
                margin={{ top: 0, right: 0, bottom: 20, left: 20 }}
                svgClassName='overflow-visible'
                handlePointer={handlePointer}
              >
                {({ xScale, yScale, yMax }) => (
                  <>
                    {/* To make sure that the lines don't go under the axis, apply a cliping path around the Lines. */}
                    <Group clipPath='url(#content)'>
                      <PatternHighlight id='pattern-highlight' />
                      <Threshold<(typeof serie)[0]>
                        id={`${Math.random()}`}
                        data={serie}
                        x={(d) => xScale(d.timestamp)}
                        y0={0}
                        y1={yMax}
                        clipAboveTo={(d) => {
                          return d.throttled > 0 ? 0 : yMax
                        }}
                        clipBelowTo={0}
                        curve={curveStep}
                        belowAreaProps={{
                          fill: `transparent`,
                        }}
                        aboveAreaProps={{
                          fill: 'url(#pattern-highlight)',
                          fillOpacity: 0.25,
                        }}
                      />

                      <LineWithShadow
                        data={serie}
                        curve={curveMonotoneX}
                        x={(point) => xScale(point.timestamp)}
                        y={(point) => yScale(point.average)}
                        stroke='hsl(var(--fpds-color-purple-7))'
                        strokeWidth={1.75}
                        strokeOpacity={1}
                      />

                      <LineWithShadow
                        data={serie}
                        curve={curveMonotoneX}
                        x={(point) => xScale(point.timestamp)}
                        y={(point) => yScale(point.peak)}
                        stroke='hsl(var(--fpds-color-orange-7))'
                        strokeWidth={1.75}
                        strokeOpacity={1}
                      />
                    </Group>

                    {tooltipIndex != null && tooltipData != null ? (
                      <>
                        <CircleMark
                          cx={tooltipData.periodStart ? xScale(tooltipData.periodStart) : undefined}
                          cy={yScale(tooltipData.average)}
                          fill='hsl(var(--fpds-color-purple-7))'
                        />
                        <CircleMark
                          cx={tooltipData.periodStart ? xScale(tooltipData.periodStart) : undefined}
                          cy={yScale(tooltipData.peak)}
                          fill='hsl(var(--fpds-color-orange-7))'
                        />
                      </>
                    ) : null}
                  </>
                )}
              </ResponsiveVisXChart>
            )}
            {tooltipIndex != null && tooltipData != null ? (
              <TooltipContainer position={position}>
                <TooltipHeader>
                  <span className='font-mono'>{formatChartTimestamp(tooltipData.periodStart, granularity)}</span>
                </TooltipHeader>
                <TooltipContent className='grid grid-cols-[auto_1fr_auto] gap-x-3 gap-y-2 items-center justify-between '>
                  <OrangeMarker />
                  <span className='text-gray-800'>Peak RPS</span>
                  <span className='ml-4 font-mono font-medium text-right'>
                    {tooltipData.peak != null ? formatNum(tooltipData.peak, true) : ''}
                  </span>

                  <PurpleMarker />
                  <span className='text-gray-800'>Average RPS</span>
                  <span className='ml-4 font-mono font-medium text-right'>
                    {tooltipData.average != null ? formatNum(tooltipData.average, true) : ''}
                  </span>

                  <HighlightMarker />
                  <span className='text-gray-800'>Throttled calls</span>
                  <span className='ml-4 font-mono font-medium text-right'>
                    {tooltipData.throttled != null && tooltipData.throttledPercentage != null
                      ? `${formatNumShort(tooltipData.throttled)} (${formatPercent(tooltipData.throttledPercentage)})`
                      : ''}
                  </span>
                </TooltipContent>
              </TooltipContainer>
            ) : null}
          </ChartGraph>
        </>
      )}
    </ChartLayout>
  )
}

function Header({ action }: Pick<InsightsChartProps, 'action'>) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { dateRange } = useDateRangeContext()

  const { data: stats, isLoading: statsLoading } = useSubscriptionStatsQuery(
    subscriptionId,
    dateRange?.startDate?.toUTC()?.toISO() ?? undefined,
    dateRange?.endDate?.toUTC()?.toISO() ?? undefined
  )
  const peakStat = stats?.stats?.find((s) => s.type === SubscriptionStatistic.PeakRps)?.data as
    | SubscriptionStatisticValue[SubscriptionStatistic.PeakRps]
    | undefined

  return (
    <ChartHeader>
      <ChartTitle
        title='Requests per Second (RPS)'
        info='Shows API usage bandwidth as average and peak requests per second, for the selected period.'
        action={action}
      />
      <ChartValue>
        <ChartMainValue>
          {peakStat?.currentPeriod != null ? (
            formatNum(peakStat.currentPeriod, true)
          ) : (
            <Skeleton width={80} height={28} />
          )}
        </ChartMainValue>
        <ChartValueComparison
          isLoading={statsLoading}
          value={peakStat?.currentPeriod}
          previousValue={peakStat?.prevPeriod}
        />
      </ChartValue>
    </ChartHeader>
  )
}

function Legend() {
  return (
    <ChartLegend>
      <ChartLegendItem>
        <OrangeMarker />
        Peak RPS
      </ChartLegendItem>
      <ChartLegendItem>
        <PurpleMarker />
        Average RPS
      </ChartLegendItem>
      <ChartLegendItem>
        <HighlightMarker />
        Throttling
      </ChartLegendItem>
    </ChartLegend>
  )
}

function ChartTable({
  isLoading,
  values,
  granularity,
}: {
  isLoading?: boolean
  values: Value[]
  granularity?: UsageCounterPeriod
}) {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell width='20%'>Time</TableCell>
          <TableCell width='20%' align='right'>
            Peak RPS
          </TableCell>
          <TableCell width='20%' align='right'>
            Average RPS
          </TableCell>
          <TableCell width='20%' align='right'>
            Throttled calls
          </TableCell>
          <TableCell width='20%' align='right'>
            Throttled %
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody columnCount={5} isLoading={isLoading}>
        <TableBodyData>
          {values.map((v) => (
            <TableRow key={v.timestamp.valueOf()}>
              <TableCell>{formatChartTimestamp(v.timestamp, granularity)}</TableCell>
              <TableCell align='right'>{v.peak != null ? formatNum(v.peak, true) : null}</TableCell>
              <TableCell align='right'>{v.average != null ? formatNum(v.average, true) : null}</TableCell>
              <TableCell align='right'>{v.throttled != null ? formatNum(v.throttled) : null}</TableCell>
              <TableCell align='right'>
                {v.throttledPercentage != null ? formatPercent(v.throttledPercentage) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBodyData>
      </TableBody>
    </Table>
  )
}
