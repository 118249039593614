import { LinkButton } from '@compass/components'
import { Table, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { TableBody, TableBodyData, TableCell, TableRow } from 'components/Table/Table'
import { formatNum } from 'helpers/format'
import { useBillingQuota } from 'hooks'
import { KeyRoundIcon, MonitorSmartphoneIcon, SettingsIcon } from 'lucide-react'

import { ApplicationsOverviewSubscriptionDetails } from '../ApplicationsOverview/ApplicationsOverviewData'
import PlanTag from '../Tags/PlanTag'
import StatusTag from '../Tags/StatusTag'
import styles from './ApplicationsCompactTable.module.scss'

type SubscriptionRowProps = {
  subscription: ApplicationsOverviewSubscriptionDetails
}

function SubscriptionRow({ subscription }: SubscriptionRowProps) {
  const { currentUsage } = useBillingQuota(subscription)

  return (
    <TableRow key={subscription.id}>
      <TableCell className={styles.applicationCell}>
        <Typography variant='bodyMMedium' className={styles.applicationName}>
          {subscription.name}
        </Typography>

        <div className={styles.property}>
          <Typography variant='bodyMMedium' className={styles.propertyValue}>
            {subscription.domain || '-'}
          </Typography>
          <Typography variant='bodyS' className={styles.propertyName}>
            Domain
          </Typography>
        </div>
      </TableCell>
      <TableCell className={styles.applicationCell}>
        <div className={styles.property}>
          <Typography variant='bodyMMedium' className={styles.usageValue}>
            {currentUsage ? formatNum(currentUsage) : '-'}
          </Typography>
          <Typography variant='bodyS' className={styles.propertyName}>
            Usage
          </Typography>
        </div>
      </TableCell>
      <TableCell className={styles.applicationCell}>
        <div className={styles.property}>
          <Typography variant='bodyMMedium' className={styles.propertyValue}>
            <PlanTag subscription={subscription} />
          </Typography>
          <Typography variant='bodyS' className={styles.propertyName}>
            Plan
          </Typography>
        </div>
      </TableCell>
      <TableCell className={styles.applicationCell}>
        <div className={styles.property}>
          <Typography variant='bodyMMedium' className={styles.propertyValue}>
            <StatusTag status={subscription.status} />
          </Typography>
          <Typography variant='bodyS' className={styles.propertyName}>
            Status
          </Typography>
        </div>
      </TableCell>
      <TableCell align='right' className={styles.actionsCell}>
        <div className={styles.actionSet}>
          <LinkButton
            isIcon
            variant='ghost'
            alt
            aria-label='Workspace API keys'
            href={buildRoute(AppRoute.ApiKeys, { subscriptionId: subscription.id })}
          >
            <KeyRoundIcon />
          </LinkButton>
          <LinkButton
            isIcon
            variant='ghost'
            alt
            aria-label='Workspace visits'
            href={buildRoute(AppRoute.IdentificationEvents, { subscriptionId: subscription.id })}
          >
            <MonitorSmartphoneIcon />
          </LinkButton>
          <LinkButton
            isIcon
            variant='ghost'
            alt
            aria-label='Workspace settings'
            href={buildRoute(AppRoute.SubscriptionSettings, { subscriptionId: subscription.id })}
          >
            <SettingsIcon />
          </LinkButton>
        </div>
      </TableCell>
    </TableRow>
  )
}

export type ApplicationsCompactTableProps = {
  subscriptions: ApplicationsOverviewSubscriptionDetails[]
}

export default function ApplicationsCompactTable({ subscriptions }: ApplicationsCompactTableProps) {
  return (
    <Table>
      <TableBody columnCount={5}>
        <TableBodyData>
          {subscriptions.map((subscription) => (
            <SubscriptionRow key={subscription.id} subscription={subscription} />
          ))}
        </TableBodyData>
      </TableBody>
    </Table>
  )
}
