import { UsageEntry } from '../types'

export const calculateTotals = (entries: UsageEntry[]) => {
  return entries.reduce(
    (acc, entry) => ({
      includedWithPlan: acc.includedWithPlan + entry.includedWithPlan,
      currentUsage: acc.currentUsage + entry.currentUsage,
      cost: entry.cost ? (acc.cost ?? 0) + parseFloat(entry.cost.replace('$', '')) : acc.cost,
    }),
    { includedWithPlan: 0, currentUsage: 0, cost: undefined as number | undefined }
  )
}
