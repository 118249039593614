import { LinkButton } from '@compass/components'
import { Info } from '@mui/icons-material'
import { TableHead, Tooltip, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { Table, TableBody, TableBodyData, TableCell, TableRow } from 'components/Table/Table'
import { formatNum } from 'helpers/format'
import { useBillingQuota } from 'hooks'
import { KeyRoundIcon, MonitorSmartphoneIcon, SettingsIcon } from 'lucide-react'

import { ApplicationsOverviewSubscriptionDetails } from '../ApplicationsOverview/ApplicationsOverviewData'
import PlanTag from '../Tags/PlanTag'
import StatusTag from '../Tags/StatusTag'
import styles from './ApplicationsTable.module.scss'

type SubscriptionRowProps = {
  subscription: ApplicationsOverviewSubscriptionDetails
}

function SubscriptionRow({ subscription }: SubscriptionRowProps) {
  const { currentUsage } = useBillingQuota(subscription)

  return (
    <TableRow key={subscription.id}>
      <TableCell>
        <Typography variant='bodyMMedium'>{subscription.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='bodyM'>{subscription.domain || '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='bodyM' className={styles.usageValue}>
          {currentUsage ? formatNum(currentUsage) : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <PlanTag subscription={subscription} />
      </TableCell>
      <TableCell>
        <StatusTag status={subscription.status} />
      </TableCell>
      <TableCell align='right'>
        <div className={styles.actionSet}>
          <LinkButton
            isIcon
            variant='ghost'
            alt
            aria-label='Workspace API keys'
            href={buildRoute(AppRoute.ApiKeys, { subscriptionId: subscription.id })}
          >
            <KeyRoundIcon />
          </LinkButton>
          <LinkButton
            isIcon
            variant='ghost'
            alt
            aria-label='Workspace visits'
            href={buildRoute(AppRoute.IdentificationEvents, { subscriptionId: subscription.id })}
          >
            <MonitorSmartphoneIcon />
          </LinkButton>
          <LinkButton
            isIcon
            variant='ghost'
            alt
            aria-label='Workspace settings'
            href={buildRoute(AppRoute.SubscriptionSettings, { subscriptionId: subscription.id })}
          >
            <SettingsIcon />
          </LinkButton>
        </div>
      </TableCell>
    </TableRow>
  )
}

export type ApplicationsTableProps = {
  subscriptions: ApplicationsOverviewSubscriptionDetails[]
}

export default function ApplicationsTable({ subscriptions }: ApplicationsTableProps) {
  return (
    <Table className={styles.table}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Domain</TableCell>
          <TableCell>
            Usage{' '}
            <Tooltip title='API calls made during the current billing period' arrow placement='top-end'>
              <Info fontSize='tiny' className={styles.tooltipIcon} />
            </Tooltip>
          </TableCell>
          <TableCell>Plan</TableCell>
          <TableCell>Status</TableCell>
          <TableCell align='right'>Go to</TableCell>
        </TableRow>
      </TableHead>

      <TableBody columnCount={6}>
        <TableBodyData>
          {subscriptions.map((subscription) => (
            <SubscriptionRow key={subscription.id} subscription={subscription} />
          ))}
        </TableBodyData>
      </TableBody>
    </Table>
  )
}
