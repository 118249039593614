import { TagColor } from 'helpers/colorToTone'
import { NormalizedUser, UserRole } from 'models'

export type ManagedUser = NormalizedUser & {
  isEditable?: boolean
  isDeletable?: boolean
  canReceiveOwnership?: boolean
  isPendingTransfer?: boolean
}

export const USER_ROLE_COLORS: Record<UserRole, TagColor> = {
  [UserRole.Admin]: 'gray',
  [UserRole.Administrator]: 'green',
  [UserRole.Owner]: 'red',
  [UserRole.Member]: 'yellow',
  [UserRole.ReadOnly]: 'blue',
}
