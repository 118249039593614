import { PropsWithChildren, ReactNode, useState } from 'react'
import { Button, composeRenderProps, TooltipTrigger } from 'react-aria-components'
import { tv } from 'tailwind-variants'

import { Tooltip } from './Tooltip'
import { focusRing } from './utils'

export const styles = tv({
  extend: focusRing,
  base: 'cursor-default',
})

export function WithTooltip(props: PropsWithChildren<{ content: ReactNode }>) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <TooltipTrigger isOpen={isOpen}>
      <Button
        onFocusChange={setIsOpen}
        onHoverStart={() => setIsOpen(true)}
        onHoverChange={setIsOpen}
        className={styles()}
      >
        {composeRenderProps(props.children, (children) => (
          <>
            {children}
            <div className='absolute left-4 right-4 bottom-0 h-px bg-white/20 forced-colors:bg-[HighlightText] hidden [.group[data-selected]:has(+[data-selected])_&]:block' />
          </>
        ))}
      </Button>
      <Tooltip>{props.content}</Tooltip>
    </TooltipTrigger>
  )
}
