import { Button } from '@compass/Button'
import { Divider, Typography } from '@mui/material'
import clsx from 'clsx'
import { formatPriceInDollars } from 'helpers/format'
import { BillingType, Discount, SubscriptionType } from 'models'
import { useMemo } from 'react'

import { calculateTotals } from '../../../helpers/calculations'
import { getTableColumns } from '../../../helpers/columns'
import { formatCost, formatOveragePrice, formatUsageValue } from '../../../helpers/format'
import { useUsageCalculations } from '../../../hooks/useUsageCalculations'
import { UsageEntry } from '../../../types'

interface UsageTableProps {
  entries: UsageEntry[]
  subscriptionType?: SubscriptionType
  billingType?: BillingType
  isRelatedVisitors?: boolean
  appliedDiscount?: Discount
}

export function UsageTable({
  entries,
  subscriptionType,
  billingType,
  isRelatedVisitors,
  appliedDiscount,
}: UsageTableProps) {
  const columns = useMemo(
    () => getTableColumns(subscriptionType, billingType, isRelatedVisitors, entries),
    [subscriptionType, billingType, isRelatedVisitors, entries]
  )

  const totals = useMemo(() => calculateTotals(entries), [entries])
  const showSimplifiedTotal = isRelatedVisitors || entries.length === 1

  const { usageCostInCents, planPriceInCents, discountAmount, finalCostInCents, tooltipContent } = useUsageCalculations(
    entries,
    subscriptionType,
    appliedDiscount
  )

  const formatCell = (key: keyof UsageEntry, value: any) => {
    if (value === undefined) return '-'
    switch (key) {
      case 'currentUsage':
        return formatUsageValue(value)
      case 'includedWithPlan':
        return formatUsageValue(value, subscriptionType)
      case 'overagePrice':
        return formatOveragePrice(value)
      case 'cost':
        return formatCost(value)
      default:
        return value
    }
  }

  const Totals = () => {
    if (subscriptionType === SubscriptionType.Paid) {
      return (
        <>
          <tr className='bg-gray-100'>
            {columns.map(({ key }, index) => {
              if (key === 'platform')
                return (
                  <td key={key} className='px-4 py-3 rounded-tl'>
                    <Typography variant='bodyM'>Total</Typography>
                  </td>
                )
              if (key === 'includedWithPlan')
                return (
                  <td key={key} className={clsx('px-4 py-3 text-right ', index === columns.length - 1 && 'rounded-tr')}>
                    <Typography variant='bodyMMedium'>{formatCell(key, entries[0][key])}</Typography>
                  </td>
                )
              if (key === 'overagePrice')
                return (
                  <td key={key} className={clsx('px-4 py-3 text-right', index === columns.length - 1 && 'rounded-tr')}>
                    <Typography variant='bodyMMedium'>{formatCell(key, entries[0][key])}</Typography>
                  </td>
                )
              if (key === 'currentUsage')
                return (
                  <td key={key} className={clsx('px-4 py-3 text-right', index === columns.length - 1 && 'rounded-tr')}>
                    <Typography variant='bodyMMedium'>{formatCell(key, totals.currentUsage)}</Typography>
                  </td>
                )
              if (key === 'cost') {
                return (
                  <td key={key} className={clsx('px-4 py-3 text-right', index === columns.length - 1 && 'rounded-tr')}>
                    <Button tooltip={tooltipContent} variant='none' className='p-0 h-auto'>
                      <Typography variant='bodyMMedium' className='border-b border-dotted border-gray-600 cursor-help'>
                        {formatPriceInDollars(usageCostInCents)}
                      </Typography>
                    </Button>
                  </td>
                )
              }
              return <td key={key} />
            })}
          </tr>
          <tr className='bg-gray-100'>
            {columns.map(({ key }) => {
              if (key === 'platform')
                return (
                  <td key={key} className='p-4 pt-0'>
                    <Typography variant='bodyM'>Plan price</Typography>
                  </td>
                )
              if (key === 'cost')
                return (
                  <td key={key} className='p-4 pt-0 text-right'>
                    <Typography variant='bodyMMedium'>{formatPriceInDollars(planPriceInCents)}</Typography>
                  </td>
                )
              return <td key={key} />
            })}
          </tr>
          {appliedDiscount?.coupon?.percentOff && (
            <tr className='bg-gray-100'>
              {columns.map(({ key }) => {
                if (key === 'platform')
                  return (
                    <td key={key} className='p-4 pt-0'>
                      <Typography variant='bodyM'>Discount ({appliedDiscount?.coupon?.percentOff}% off)</Typography>
                    </td>
                  )
                if (key === 'cost')
                  return (
                    <td key={key} className='p-4 pt-0 text-right'>
                      <Typography variant='bodyMMedium' className='text-red-800'>
                        -{formatPriceInDollars(discountAmount)}
                      </Typography>
                    </td>
                  )
                return <td key={key} />
              })}
            </tr>
          )}
          <tr className='bg-gray-100'>
            <td colSpan={columns.length} className='px-4 py-0'>
              <Divider className='border-dashed' />
            </td>
          </tr>
          <tr className='bg-gray-100'>
            {columns.map(({ key }) => {
              if (key === 'platform')
                return (
                  <td key={key} className='p-4'>
                    <Typography variant='bodyLMedium'>Total cost</Typography>
                  </td>
                )
              if (key === 'cost')
                return (
                  <td key={key} className='p-4 text-right'>
                    <Typography variant='bodyLMedium'>{formatPriceInDollars(finalCostInCents)}</Typography>
                  </td>
                )
              return <td key={key} />
            })}
          </tr>
        </>
      )
    }

    return (
      <tr className='bg-gray-100'>
        {columns.map(({ key }, index) => {
          if (key === 'platform')
            return (
              <td key={key} className='px-4 py-3 rounded-tl'>
                <Typography variant='bodyM'>Total</Typography>
              </td>
            )
          if (key === 'currentUsage')
            return (
              <td key={key} className={clsx('px-4 py-3 text-right', index === columns.length - 1 && 'rounded-tr')}>
                <Typography variant='bodyMMedium'>{formatCell(key, totals.currentUsage)}</Typography>
              </td>
            )
          if (key === 'includedWithPlan')
            return (
              <td key={key} className={clsx('px-4 py-3 text-right', index === columns.length - 1 && 'rounded-tr')}>
                <Typography variant='bodyMMedium'>{formatCell(key, entries[0][key])}</Typography>
              </td>
            )
          if (key === 'cost' && totals.cost !== undefined)
            return (
              <td key={key} className={clsx('px-4 py-3 text-right', index === columns.length - 1 && 'rounded-tr')}>
                <Typography variant='bodyMMedium'>${totals.cost.toFixed(2)}</Typography>
              </td>
            )
          return <td key={key} />
        })}
      </tr>
    )
  }

  return (
    <table className='w-full'>
      <thead>
        <tr className='border-b'>
          {columns.map(({ key, header }, index) => (
            <th
              key={key}
              className={clsx(
                'min-w-48 px-4 py-3 bg-white',
                key === 'platform' ? 'text-left' : 'text-right',
                index === 0 ? 'w-full' : 'w-48 max-w-48'
              )}
            >
              <Typography variant='caption'>{header}</Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={columns.length} className={showSimplifiedTotal ? 'h-3' : 'h-1.5'} />
        </tr>
        {!showSimplifiedTotal &&
          entries.map((currentEntry, index) => (
            <tr key={index}>
              {columns.map(({ key }) => {
                if (key === 'includedWithPlan' || key === 'overagePrice') {
                  const middleIndex = Math.floor(entries.length / 2)
                  if (index !== middleIndex) {
                    return <td key={key} />
                  }
                }

                if (key === 'cost') {
                  const middleIndex = Math.floor(entries.length / 2)
                  if (index !== middleIndex) {
                    return <td key={key} className='px-4 py-1.5 text-right' />
                  }

                  return (
                    <td key={key} className='px-4 py-1.5 text-right'>
                      <Button tooltip={tooltipContent} variant='none' className='p-0 h-auto'>
                        <Typography
                          className='relative border-b border-dotted border-gray-600 cursor-help'
                          variant='bodyM'
                        >
                          {formatPriceInDollars(usageCostInCents)}
                        </Typography>
                      </Button>
                    </td>
                  )
                }

                return (
                  <td
                    key={key}
                    className={clsx(
                      'px-4 py-1.5',
                      key === 'platform' ? 'text-left' : 'text-right',
                      key !== 'platform' && 'text-gray-800',
                      index === entries.length - 1 && 'pb-3'
                    )}
                  >
                    <Typography variant='bodyM'>{formatCell(key, currentEntry[key])}</Typography>
                  </td>
                )
              })}
            </tr>
          ))}
        <Totals />
      </tbody>
    </table>
  )
}
