import * as amplitude from '@amplitude/analytics-browser'
import { Link, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { Header, MainColumn, SubHeader } from 'features/commonUI'
import { useAuth, useDocumentTitle } from 'hooks'
import { ampli } from 'models/ampli'
import { MouseEvent } from 'react'
import { useHistory } from 'react-router-dom'

import { Integrations } from './components/Integrations/Integrations'

export function GetStartedPage() {
  const history = useHistory()
  const { accessTokenPayload } = useAuth()
  const userId = accessTokenPayload?.id

  useDocumentTitle('Get Started')

  if (ampli.client) {
    const identifyEvent = new amplitude.Identify()
    identifyEvent.setOnce('usedAppAskAI', false)
    ampli.client.identify(identifyEvent, { user_id: userId })
  }

  const inviteMemberOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    ampli.inviteUserClicked({ 'App Page Path': AppRoute.SubscriptionGetStarted })
    history.push(AppRoute.UserManagement, { isUserDialogOpen: true })
  }

  return (
    <>
      <Header title='Get Started' />
      <MainColumn>
        <SubHeader
          title={<Typography variant='h2'>Install Fingerprint</Typography>}
          description={
            <Typography variant='bodyM'>
              Choose a platform to get started. Need help with installation?{' '}
              <Link
                component='a'
                style={{ textDecoration: 'none' }}
                href={buildRoute(AppRoute.UserManagement)}
                onClick={inviteMemberOnClick}
              >
                Invite a team member
              </Link>
            </Typography>
          }
          data-testid='installpage-header'
        />
        <Integrations />
      </MainColumn>
    </>
  )
}
