import { Stack } from '@compass/components'
import { PropsWithChildren } from 'react'

export function ChartLayout({ children }: PropsWithChildren) {
  return (
    <Stack direction='column' gap={4}>
      {children}
    </Stack>
  )
}
