import { DateTime } from 'luxon'
import { UsageCounterPeriod } from 'models'

const monthFormat = 'LLL yyyy'
const dayFormat = 'dd LLL yyyy'
const dateTimeFormat = 'dd LLL yyyy HH:mm'

export function formatChartTimestamp(dt?: DateTime, granularity?: UsageCounterPeriod) {
  if (dt == null) {
    return ''
  }

  if (granularity == null) {
    return dt.toFormat(dateTimeFormat)
  }

  switch (granularity) {
    case UsageCounterPeriod.Month:
      return dt.toFormat(monthFormat)

    case UsageCounterPeriod.Day:
      return dt.toFormat(dayFormat)

    case UsageCounterPeriod.Hour:
      return dt.toFormat(dateTimeFormat)
  }
}
