import { Skeleton, Typography } from '@mui/material'
import clsx from 'clsx'
import { Discount, SubscriptionType } from 'models'

import { formatNum, formatPriceInDollars } from '../../../../../helpers/format'
import { useUsageCalculations } from '../../../hooks/useUsageCalculations'
import { UsageEntry } from '../../../types'
import styles from './UsageMeter.module.scss'

interface UsageMeterProps {
  totalUsage: number
  projected?: number
  isLoading?: boolean
  entries: UsageEntry[]
  subscriptionType?: SubscriptionType
  appliedDiscount?: Discount
}

interface BarTooltipProps {
  type: string
  label: string
  amount: number
  isTop?: boolean
}

const BarTooltip = ({ type, label, amount, isTop }: BarTooltipProps) => (
  <dl className={clsx(styles.tooltip, isTop ? styles.top : styles.bottom, type === 'current' && styles.current)}>
    <dt>
      <Typography variant='bodyM' className={styles.label}>
        {label}
      </Typography>
    </dt>
    <dd>
      <Typography variant='bodyMMedium' className={styles.value}>
        {formatNum(amount)}
      </Typography>
    </dd>
  </dl>
)

export const UsageMeter = ({
  totalUsage,
  projected,
  isLoading,
  entries,
  subscriptionType,
  appliedDiscount,
}: UsageMeterProps) => {
  const { finalCostInCents } = useUsageCalculations(entries, subscriptionType, appliedDiscount)

  if (isLoading) {
    return (
      <div className={styles.meterWrapper}>
        <Skeleton width='100%' height={8} className={`${styles.loadingMeter} transform-none`} />
        <Skeleton width={64} height={28} className='flex-shrink-0 transform-none' />
      </div>
    )
  }

  const availableSpace = `75%`

  const normalizedValues = {
    current: (totalUsage / (projected || totalUsage)) * 100,
    projected: projected ? 100 : 0,
  }

  const projectedStart = `${normalizedValues.current}%`
  const shouldShowProjected = projected !== undefined && projected >= totalUsage

  return (
    <div className={styles.meterWrapper}>
      <div className={styles.meter}>
        <div
          className={styles.section}
          data-testid='usage-meter-section'
          style={
            {
              width: availableSpace,
              '--current-end': projectedStart,
              '--projected-position': `${normalizedValues.projected}%`,
            } as React.CSSProperties
          }
          data-has-projected={shouldShowProjected}
        >
          <div className={styles.barContainer} style={{ width: `${normalizedValues.current}%` }}>
            <div
              className={styles.item}
              style={{
                backgroundColor: '#F35B22',
                borderColor: '#BE400F',
              }}
            />
            <BarTooltip type='current' label='Current' amount={totalUsage} isTop />
          </div>
          {shouldShowProjected && (
            <div
              className={styles.barContainer}
              style={{
                position: 'absolute',
                left: `${normalizedValues.projected}%`,
              }}
            >
              <BarTooltip type='projected' label='Projected' amount={projected} isTop={false} />
            </div>
          )}
        </div>
      </div>
      {finalCostInCents > 0 && <Typography variant='h2'>{formatPriceInDollars(finalCostInCents)}</Typography>}
    </div>
  )
}
