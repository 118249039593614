import { calculateGranularityFromRange, CustomDateRange, PredefinedRange } from 'helpers/dateRange'
import { DateTime } from 'luxon'
import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react'

export type DateRangeContextValue = {
  today: DateTime
  dateRange?: CustomDateRange | PredefinedRange
  setDateRange?: React.Dispatch<React.SetStateAction<CustomDateRange | PredefinedRange | undefined>>
}
const DateRangeContext = createContext<DateRangeContextValue>({ today: DateTime.now() })

export function DateRangeContextProvider({ children }: PropsWithChildren) {
  const [dateRange, setDateRange] = useState<CustomDateRange | undefined>(undefined)
  const today = useMemo(() => DateTime.now(), [])
  const value = useMemo(
    () => ({
      dateRange,
      setDateRange,
      today,
    }),
    [dateRange, today]
  )

  return <DateRangeContext.Provider value={value}>{children}</DateRangeContext.Provider>
}

export function useDateRangeContext() {
  return useContext(DateRangeContext)
}

export function calculateGranularity(startDate?: DateTime, endDate?: DateTime, legacy?: boolean) {
  if (startDate == null || endDate == null) {
    return undefined
  }
  return calculateGranularityFromRange({ startDate: startDate, endDate: endDate }, legacy)
}
