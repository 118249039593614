import { BillingType } from 'models'

export interface UsageEntry {
  platform: string
  includedWithPlan: number
  overagePrice?: number
  currentUsage: number
  cost?: string
}

export interface UsageTableColumn {
  key: keyof UsageEntry
  header: string
}

export const COLUMN_HEADERS = {
  PLATFORM: 'PLATFORM',
  INCLUDED_PLAN: (billingType?: BillingType) =>
    `INCLUDED W/ PLAN${billingType === BillingType.UniqueVisitors ? ' (VISITORS)' : ''}`,
  INCLUDED_CONTRACT: (billingType?: BillingType) =>
    `INCLUDED W/ CONTRACT${billingType === BillingType.UniqueVisitors ? ' (VISITORS)' : ''}`,
  CURRENT_USAGE: (billingType?: BillingType) =>
    `CURRENT ${billingType === BillingType.UniqueVisitors ? 'VISITORS' : 'USAGE'}`,
  OVERAGE_PRICE: 'OVERAGE PRICE',
  COST: 'COST',
} as const
