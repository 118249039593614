import { Button, SelectItem, SelectListBox, SelectPopover } from '@compass/components'
import { ChevronsUpDownIcon } from 'lucide-react'
import { SubscriptionDistribution } from 'models'
import { useCallback, useMemo } from 'react'
import { Select } from 'react-aria-components'

import { ampli } from '../../../../models/ampli'
import { OPTION_MAPPING } from './const'
import styles from './Distribution.module.scss'

const selectOptions = Object.entries(OPTION_MAPPING).map(([key, value]) => ({
  key: key as SubscriptionDistribution,
  label: value,
}))

export function DistributionAction({
  selected,
  all,
  unavailable,
  onSelect,
  blockIndex,
}: {
  selected: SubscriptionDistribution
  all: SubscriptionDistribution[]
  unavailable: Set<SubscriptionDistribution>
  onSelect: (selected: SubscriptionDistribution) => void
  blockIndex: number
}) {
  const handleItemClick = useCallback(
    (item: SubscriptionDistribution) => {
      ampli.chartSettingsChanged({
        chartType: 'Distribution',
        metricName: selected,
        newMetricName: item,
        slotColumn: blockIndex,
        slotRow: 2,
      })
      onSelect(item)
    },
    [blockIndex, onSelect, selected]
  )

  const options = useMemo(() => {
    const set = new Set(all)
    return selectOptions.filter((o) => set.has(o.key))
  }, [all])

  return (
    <div className={styles.action}>
      <Select<typeof selectOptions>
        aria-label='Change displayed metric'
        selectedKey={selected}
        onSelectionChange={handleItemClick}
        onOpenChange={(open) => {
          if (open) {
            ampli.chartSettingsOpened({ chartType: 'Distribution', metricName: selected })
          }
        }}
      >
        <Button isIcon variant='ghost' aria-label='Change displayed metric'>
          <ChevronsUpDownIcon />
        </Button>
        <SelectPopover>
          <SelectListBox>
            {options?.map((option) => (
              <SelectItem key={option.key} id={option.key} value={option} isDisabled={unavailable.has(option.key)}>
                {option.label}
              </SelectItem>
            ))}
          </SelectListBox>
        </SelectPopover>
      </Select>
    </div>
  )
}
