import { Stack } from '@compass/components'
import { PatternLines } from '@visx/pattern'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'

export function ChartLegend({ children }: PropsWithChildren) {
  return (
    <Stack direction='row' gap={4} className='items-center justify-end'>
      {children}
    </Stack>
  )
}

export function ChartLegendMarker({ children, className, ...props }: React.SVGProps<SVGRectElement>) {
  return (
    <svg width={8} height={8} className={clsx('border rounded-[2px]', className)}>
      {children}
      <rect x={0} y={0} width={8} height={8} strokeWidth={1} {...props} />
    </svg>
  )
}

export const OrangeMarker = () => (
  <ChartLegendMarker fill='hsl(var(--fpds-color-orange-7))' className='border-orange-800' />
)

export const PurpleMarker = () => (
  <ChartLegendMarker fill='hsl(var(--fpds-color-purple-7))' className='border-purple-800' />
)

export const HighlightMarker = () => (
  <ChartLegendMarker fill='url(#pattern-highlight)' className='border-gray-800'>
    <PatternHighlight id='pattern-highlight' />
  </ChartLegendMarker>
)

export function ChartLegendItem({ children }: PropsWithChildren) {
  return <div className='flex gap-2 items-center text-xs text-gray-800'>{children}</div>
}

export function PatternHighlight({ id }: { id: string }) {
  return (
    <PatternLines
      id={id}
      height={4}
      width={4}
      stroke='hsl(var(--fpds-color-gray-6))'
      strokeWidth={1}
      orientation={['diagonalRightToLeft']}
    />
  )
}
