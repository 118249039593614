import { Stack } from '@compass/components'
import { Paper } from '@mui/material'
import { ErrorBoundary } from '@rollbar/react'
import { UsageCounterPeriod } from 'models'
import { FunctionComponent, useMemo, useState } from 'react'

import { ChartError } from './components/ChartError'
import { ChartGraph } from './components/ChartGraph'
import { ChartHeader } from './components/ChartHeader'
import { ChartLayout } from './components/ChartLayout'
import { ChartTitle } from './components/ChartTitle'
import { DisplaySelector } from './components/DisplaySelector'
import { GranularitySelector } from './components/GranularitySelector'
import { ApiUsageContent } from './content/ApiUsageContent'
import { EventsPerVisitorContent } from './content/EventsPerVisitorContent'
import { FailedApiCallsContent } from './content/FailedApiCallsContent'
import { RpsContent } from './content/RpsContent'
import { InsightsChartProps } from './InsightsChartProps'
import { InsightsChartSelector, OverviewChartType, selectOptions } from './InsightsChartSelector'

const CHART_KEY_COMPONENT: Record<OverviewChartType, FunctionComponent<InsightsChartProps>> = {
  api_usage: ApiUsageContent,
  events_per_visitor: EventsPerVisitorContent,
  failed_api_calls: FailedApiCallsContent,
  rps: RpsContent,
}

export function InsightsChart() {
  const [granularity, setGranularity] = useState<UsageCounterPeriod>()
  const [display, setDisplay] = useState<'chart' | 'table'>('chart')

  const [selectedChart, setSelectedChart] = useState<OverviewChartType>('api_usage')
  const label = useMemo(() => selectOptions.find((o) => o.key === selectedChart)?.label ?? '', [selectedChart])

  const Component = CHART_KEY_COMPONENT[selectedChart]

  return (
    <Paper className='relative p-6'>
      <InsightsChartSelector
        selectedChart={selectedChart}
        onSelectionChange={setSelectedChart}
        className='absolute top-[17px] right-2'
      />
      <ErrorBoundary
        errorMessage={`InsightsChart error for ${selectedChart}`}
        fallbackUI={() => (
          <ChartLayout>
            <ChartHeader>
              <ChartTitle title={label} />
            </ChartHeader>
            <ChartGraph>
              <ChartError />
            </ChartGraph>
          </ChartLayout>
        )}
      >
        <Component
          action={
            <Stack gap={2} className='flex-grow flex-wrap items-center justify-start sm:justify-end mr-6 -my-1'>
              <GranularitySelector value={granularity} onChange={setGranularity} />
              <DisplaySelector value={display} onChange={setDisplay} />
            </Stack>
          }
          granularity={granularity}
          showTable={display === 'table'}
        />
      </ErrorBoundary>
    </Paper>
  )
}
