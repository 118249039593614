import { LinePath } from '@visx/shape'
import { ComponentProps } from 'react'

export function LineWithShadow<T>({
  background = 'white',
  ...lineProps
}: ComponentProps<typeof LinePath<T>> & { background?: string }) {
  return (
    <>
      <LinePath {...lineProps} stroke={background} strokeWidth={5} strokeOpacity={1} />
      <LinePath {...lineProps} />
    </>
  )
}
