import { CustomDateRange, PredefinedRange } from 'helpers/dateRange'
import { useMemo } from 'react'

import { Value } from './useEventsPerVisitorComparison'

export function useEventsPerVisitorTooltipData(
  tooltipIndex: number | undefined,
  values: Value[],
  dateRange: CustomDateRange | PredefinedRange | undefined
) {
  return useMemo(() => {
    if (tooltipIndex == null) {
      return null
    }

    const eventCount = values?.[tooltipIndex]?.eventCount
    const visitorCount = values?.[tooltipIndex]?.visitorCount
    const ratio =
      eventCount == null || visitorCount == null || visitorCount === 0 ? 1 : Math.round(eventCount / visitorCount)

    return {
      periodStart: values?.[tooltipIndex]?.timestamp ?? dateRange?.startDate,
      periodEnd: values?.[tooltipIndex + 1]?.timestamp ?? dateRange?.endDate,
      eventCount,
      visitorCount,
      ratio,
    }
  }, [dateRange?.endDate, dateRange?.startDate, tooltipIndex, values])
}
