import { WithTooltip } from '@compass/components'
import { Typography } from '@mui/material'
import { InfoIcon } from 'lucide-react'
import { ReactNode } from 'react'

export function ChartTitle({ title, info, action }: { title: string; info?: string; action?: ReactNode }) {
  return (
    <div className='flex gap-x-2 gap-y-4 items-center flex-wrap'>
      <Typography variant='bodyMMedium'>{title}</Typography>
      {info ? (
        <WithTooltip content={info}>
          <InfoIcon className='size-3 text-gray-800' />
        </WithTooltip>
      ) : null}
      {action}
    </div>
  )
}
