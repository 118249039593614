import { Button } from '@compass/components'
import {
  AttachMoneyOutlined,
  AutoAwesomeOutlined,
  PasswordOutlined,
  PeopleAltOutlined,
  SettingsSuggestOutlined,
  SmartToyOutlined,
} from '@mui/icons-material'
import { InputLabel, Radio, RadioGroup, Typography, useMediaQuery, useTheme } from '@mui/material'
import clsx from 'clsx'
import { APITypesMap, GenericError, REGIONS } from 'const'
import { useIsFirstRender } from 'hooks/firstRender'
import { RegionCode } from 'models'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { ampli } from '../../../../models/ampli'
import OnboardingWrapper from '../OnboardingWrapper/OnboardingWrapper'
import styles from './CreateAppForm.module.scss'

const availableRegions = Object.values(REGIONS)

const fraudTypeOptions = [
  {
    title: 'Account Sharing',
    icon: PeopleAltOutlined,
    value: 'accountSharing',
  },
  {
    title: 'Account Takeover',
    icon: PasswordOutlined,
    value: 'accountTakeover',
  },
  {
    title: 'Bot Detection',
    icon: SmartToyOutlined,
    value: 'botDetection',
  },
  {
    title: 'Payment Fraud',
    icon: AttachMoneyOutlined,
    value: 'paymentFraud',
  },
  {
    title: 'Personalization',
    icon: SettingsSuggestOutlined,
    value: 'personalization',
  },
  {
    title: 'Other',
    icon: AutoAwesomeOutlined,
    value: 'other',
  },
] as const

enum CollectionSteps {
  FraudType,
  SelectRegion,
}

type FormData = Pick<
  APITypesMap['subscriptionStart']['body'],
  | 'name'
  | 'domain'
  | 'regionCode'
  | 'fraudType'
  | 'otherFraudType'
  | 'jobLevel'
  | 'jobFunction'
  | 'privacyPolicy'
  | 'termsOfService'
>

export type Props = {
  isLoading?: boolean
  error?: GenericError | null
  onSubmit: (data: FormData) => Promise<void>
}

export default function CreateAppForm({ isLoading, onSubmit }: Props) {
  const [currentRegionSelection, setCurrentRegionSelection] = useState<RegionCode | null>(null)
  const [currentFraudType, setCurrentFraudType] = useState<string | null>(null)
  const theme = useTheme()
  const isFirstRender = useIsFirstRender()

  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const formMethods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      name: 'My First App',
      privacyPolicy: true,
      termsOfService: true,
      fraudType: null,
      otherFraudType: null,
    },
  })
  const {
    handleSubmit,
    register,
    formState: { isValid },
    getValues,
    watch,
    setValue,
  } = formMethods

  const [step, setStep] = useState<number>(CollectionSteps.FraudType)
  const [formShown, setFormShown] = useState(false)

  useEffect(() => {
    if (step === CollectionSteps.FraudType) {
      ampli.useCaseSelectorShown()
    }

    if (step !== CollectionSteps.SelectRegion || formShown) {
      return
    }
    ampli.regionSelectionShown({ context: 'onboarding' })
    setFormShown(true)
  }, [formShown, step])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'regionCode') {
        setCurrentRegionSelection(value.regionCode as RegionCode)
      } else if (name === 'fraudType' && value.fraudType) {
        setCurrentFraudType(value.fraudType)
      }
    })
    return () => subscription.unsubscribe()
  }, [setValue, watch])

  const handleBackStep = () => {
    if (step === CollectionSteps.SelectRegion) {
      setStep(CollectionSteps.FraudType)
    }
  }

  return (
    <OnboardingWrapper classes={{ root: styles.wrapper }}>
      <form className={styles.block} onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formMethods}>
          <input type='hidden' {...register('name')} />

          <div className={clsx(styles.step, step === CollectionSteps.FraudType ? styles.activeStep : null)}>
            <Typography className={styles.heading} variant='h1' data-testid='appform-header-problem-to-solve'>
              What problem are you working to solve?
            </Typography>
            <Typography className={styles.subheader}>This helps us customize your Fingerprint experience.</Typography>
            <RadioGroup
              name='fraudType'
              className={clsx('mb-8', styles.radioGroupProblemOnGrid, smDown ? styles.smallScreen : undefined)}
            >
              {fraudTypeOptions.map((option) => (
                <div
                  className={clsx(
                    styles.radioItem,
                    styles.noShadow,
                    option.value === currentFraudType ? styles.radioItemSelected : undefined
                  )}
                  key={`item_${option.value}`}
                >
                  <InputLabel
                    className={clsx(styles.radioLabel, styles.center)}
                    data-testid={`fraudType_${option.value}`}
                  >
                    <Radio
                      size='small'
                      className={clsx(styles.radioButton, styles.radioButtonUpperRightCorner)}
                      value={option.value}
                      checked={getValues('fraudType') === option.value}
                      {...register('fraudType')}
                    />
                    <div className={styles.groupV}>
                      <div
                        className={clsx(
                          styles.iconContainer,
                          option.value === currentFraudType ? styles.iconSelected : styles.icon
                        )}
                      >
                        <option.icon />
                      </div>
                      <div className={styles.optionTitle}>{option.title}</div>
                    </div>
                  </InputLabel>
                </div>
              ))}
            </RadioGroup>

            <div className={clsx('flex justify-end gap-4', styles.buttonGroup)}>
              <Button
                variant='ghost'
                size='lg'
                alt
                onPress={() => {
                  setStep(CollectionSteps.SelectRegion)
                }}
              >
                Skip
              </Button>
              <Button
                isDisabled={currentFraudType === null}
                data-testid='fraudTypeNextStepButton'
                size='lg'
                onPress={() => {
                  if (currentFraudType) {
                    ampli.useCaseSelected({ useCase: currentFraudType })
                  }
                  setStep(CollectionSteps.SelectRegion)
                }}
              >
                Next step
              </Button>
            </div>
          </div>

          <div className={clsx(styles.step, step === CollectionSteps.SelectRegion ? styles.activeStep : null)}>
            <Typography className={styles.heading} variant='h1' data-testid='appform-header-last-step'>
              Last step, pick a server region
            </Typography>
            <Typography className={styles.subheader}>
              Select a region nearest your users so our API remains blazingly fast. Also, this is where your data will
              be processed and stored.
            </Typography>

            <RadioGroup name='regionCode' className={clsx(styles.radioGroup, smDown ? styles.smallScreen : undefined)}>
              {availableRegions.map((region) => (
                <div
                  className={clsx(
                    styles.radioItem,
                    styles.noShadow,
                    currentRegionSelection === region.code ? styles.radioItemSelected : undefined
                  )}
                  key={`item_${region.code}`}
                >
                  <InputLabel className={styles.radioLabel} data-testid={`region_${region.code}`}>
                    <Radio
                      size='small'
                      className={styles.radioButton}
                      value={region.code}
                      {...register('regionCode')}
                    />
                    <div className={styles.groupV}>
                      <div className={styles.optionTitle}>{region.shortTitle}</div>
                      <div className={styles.optionSubtitle}>{region.regionName}</div>
                    </div>
                    {region.label && <div className={styles.optionLabel}>{region.label}</div>}
                  </InputLabel>
                </div>
              ))}
            </RadioGroup>
            <div className={styles.buttons}>
              <Button className={styles.button} variant='ghost' size='lg' fullWidth={smDown} onPress={handleBackStep}>
                Back
              </Button>
              <Button
                isDisabled={isFirstRender || !isValid || isLoading || currentRegionSelection === null}
                className={styles.button}
                data-testid='onboardingSubmitButton'
                size='lg'
                fullWidth={smDown}
                type='submit'
              >
                Done
              </Button>
            </div>
          </div>
        </FormProvider>
      </form>
    </OnboardingWrapper>
  )
}
