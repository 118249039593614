import * as amplitude from '@amplitude/analytics-browser'
import { DateRangeContextProvider } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useAuth, useDocumentTitle, useSubscription } from 'hooks'
import { useCurrentUser } from 'hooks/api/users'
import { Settings } from 'luxon'
import { useEffect, useState } from 'react'

import { ampli } from '../../models/ampli'
import SubscriptionOverview from './components/SubscriptionOverview/SubscriptionOverview'

export function SubscriptionOverviewPage() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const [tzReady, setTzReady] = useState(false)
  const { accessTokenPayload } = useAuth()
  const userId = accessTokenPayload?.id

  if (ampli.client) {
    const identifyEvent = new amplitude.Identify()
    identifyEvent.setOnce('usedAppAskAI', false)
    ampli.client.identify(identifyEvent, { user_id: userId })
  }

  // Prefetch the current user for timezone.
  const { isLoading, data: currentUser } = useCurrentUser()
  useEffect(() => {
    if (currentUser?.timezone && !isLoading) {
      Settings.defaultZone = currentUser.timezone
      setTzReady(true)
    }
  }, [currentUser?.timezone, isLoading])
  const { data: subscription } = useSubscription(currentSubscriptionId)

  useDocumentTitle(`Overview - ${subscription?.name ?? 'Subscription'}`)

  return tzReady ? (
    <DateRangeContextProvider>
      <SubscriptionOverview />
    </DateRangeContextProvider>
  ) : null
}
