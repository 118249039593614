import { useQuery, useQueryClient } from '@tanstack/react-query'
import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from 'hooks/api/base'
import { useToast } from 'hooks/toast'
import { useAuth } from 'hooks/user'
import { ampli } from 'models/ampli'

import { useCurrentUser } from './users'

const myInvitationsCacheKey = 'myInvitations'
const invitationsCacheKey = 'invitations'

export function useMyInvitations() {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: [myInvitationsCacheKey],
    queryFn: () => extractData(withErrorHandling(buildRequest('getMyInvitations'))),
  })
}

export function useInvitations() {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: [invitationsCacheKey],
    queryFn: () => extractData(withErrorHandling(buildRequest('getInvitations'))),
  })
}

export function useConfirmInvitation() {
  return useRequestMutation('confirmTransfer', {
    errorHandling: { forceToast: true },
  })
}

export function useAcceptInvitation() {
  const { data: user } = useCurrentUser()
  const { mutate, ...props } = useConfirmInvitation()
  const { showToast } = useToast()
  const { setCredentials } = useAuth()

  return {
    mutate: (invitationId: string, onSuccessCallback: () => void) =>
      mutate(
        { data: { action: 'accept', invitationId } },
        {
          onSuccess: ({ accessToken, refreshToken }) => {
            onSuccessCallback()
            setCredentials({ accessToken, refreshToken })

            if (user) {
              ampli.identify(user.id, { changedAccounts: true })
            }
            ampli.accountInvitationAccepted()
          },
          onError: () => {
            showToast({
              message: 'An error occurred when accepting the invitation. Please try again.',
              severity: 'error',
            })
          },
        }
      ),
    ...props,
  }
}

export function useIgnoreInvitation() {
  const { data: user } = useCurrentUser()
  const { mutate, ...props } = useConfirmInvitation()
  const { showToast } = useToast()

  const queryClient = useQueryClient()

  return {
    mutate: (invitationId: string, declineAllFutureRequestsFromAccount: boolean, onSuccessCallback: () => void) =>
      mutate(
        { data: { action: 'ignore', invitationId, declineAllFutureRequestsFromAccount } },
        {
          onSuccess: () => {
            showToast({ message: 'Invitation successfully ignored.', severity: 'success' })
            onSuccessCallback()

            queryClient.invalidateQueries([myInvitationsCacheKey])

            ampli.accountInvitationDeclined()
            if (user) {
              ampli.identify(user.id, { changedAccounts: false })
            }
          },
          onError: () => {
            showToast({
              message: 'An error occurred when declining the invitation. Please try again.',
              severity: 'error',
            })
          },
        }
      ),
    ...props,
  }
}

export function useCreateInvitationMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('createInvitation', {
    onSuccess: () => queryClient.invalidateQueries([invitationsCacheKey]),
  })
}

export function useUpdateInvitationMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('updateInvitation', {
    onSuccess: () => queryClient.invalidateQueries([invitationsCacheKey]),
  })
}

export function useDeleteInvitationMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('deleteInvitation', {
    onSuccess: () => queryClient.invalidateQueries([invitationsCacheKey]),
  })
}
