import { Button, ButtonGroup } from '@compass/components'
import { calculateGranularity, useDateRangeContext } from 'features/commonUI'
import { UsageCounterPeriod } from 'models'
import { useEffect } from 'react'

type GranularitySelectorProps = {
  value?: UsageCounterPeriod
  onChange: (granularity: UsageCounterPeriod) => void
}

export function GranularitySelector({ value, onChange }: GranularitySelectorProps) {
  const { dateRange } = useDateRangeContext()
  const { startDate, endDate } = dateRange ?? {}
  const defaultGranularity = calculateGranularity(startDate, endDate)

  const hourlyDisabled = endDate == null || startDate == null || (endDate.diff(startDate, 'days').days ?? 0) > 7
  const dailyDisabled =
    endDate == null ||
    startDate == null ||
    (endDate.diff(startDate, 'days').days ?? 0) < 1 ||
    (endDate.diff(startDate, 'months').months ?? 0) > 6
  const monthlyDisabled = endDate == null || startDate == null || (endDate.diff(startDate, 'months').months ?? 0) < 1

  const selected = value ?? defaultGranularity

  useEffect(() => {
    if (value == null && defaultGranularity != null) {
      onChange(defaultGranularity)
    } else if (
      defaultGranularity != null &&
      ((value === UsageCounterPeriod.Hour && hourlyDisabled) ||
        (value === UsageCounterPeriod.Day && dailyDisabled) ||
        (value === UsageCounterPeriod.Month && monthlyDisabled))
    ) {
      onChange(defaultGranularity)
    }
  }, [dailyDisabled, defaultGranularity, hourlyDisabled, monthlyDisabled, onChange, value])

  return (
    <ButtonGroup>
      <Button
        variant='secondary'
        size='sm'
        isDisabled={hourlyDisabled}
        onPress={() => onChange(UsageCounterPeriod.Hour)}
        tone={selected === UsageCounterPeriod.Hour ? 'background2' : undefined}
        className={selected === UsageCounterPeriod.Hour ? '[&_svg]:text-current shadow-inset-t-black-200' : undefined}
        aria-label='Use hourly aggregation'
      >
        Hourly
      </Button>
      <Button
        variant='secondary'
        size='sm'
        isDisabled={dailyDisabled}
        onPress={() => onChange(UsageCounterPeriod.Day)}
        tone={selected === UsageCounterPeriod.Day ? 'background2' : undefined}
        className={selected === UsageCounterPeriod.Day ? '[&_svg]:text-current shadow-inset-t-black-200' : undefined}
        aria-label='Use daily aggregation'
      >
        Daily
      </Button>
      <Button
        variant='secondary'
        size='sm'
        isDisabled={monthlyDisabled}
        onPress={() => onChange(UsageCounterPeriod.Month)}
        tone={selected === UsageCounterPeriod.Month ? 'background2' : undefined}
        className={selected === UsageCounterPeriod.Month ? '[&_svg]:text-current shadow-inset-t-black-200' : undefined}
        aria-label='Use monthly aggregation'
      >
        Monthly
      </Button>
    </ButtonGroup>
  )
}
