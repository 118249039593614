import { Skeleton, Table } from '@mui/material'
import { curveMonotoneX } from '@visx/curve'
import { Group } from '@visx/group'
import { TableBody, TableBodyData, TableCell, TableHead, TableRow } from 'components/Table/Table'
import { useDateRangeContext } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { formatNum } from 'helpers/format'
import { useCurrentUser } from 'hooks/api/users'
import { SubscriptionStatistic, SubscriptionStatisticValue, UsageCounterPeriod } from 'models'

import { useInsightsStats } from '../../../WorkspaceInsights/useInsightsStats'
import { ChartError, ChartNoData } from '../components/ChartError'
import { ChartGraph } from '../components/ChartGraph'
import { ChartHeader } from '../components/ChartHeader'
import { ChartLayout } from '../components/ChartLayout'
import { ChartLegend, ChartLegendItem, OrangeMarker, PurpleMarker } from '../components/ChartLegend'
import { ChartLoading } from '../components/ChartLoading'
import { ChartTitle } from '../components/ChartTitle'
import { ChartMainValue, ChartValue, ChartValueComparison } from '../components/ChartValue'
import { CircleMark } from '../components/CircleMark'
import { LineWithShadow } from '../components/LineWithShadow'
import { TooltipContainer, TooltipContent, TooltipHeader } from '../components/Tooltip'
import { useEventsPerVisitorComparison, Value } from '../hooks/useEventsPerVisitorComparison'
import { useEventsPerVisitorTooltipData } from '../hooks/useEventsPerVisitorTooltipData'
import { useTimelineIndexer } from '../hooks/useTimelineIndexer'
import { InsightsChartProps } from '../InsightsChartProps'
import { ResponsiveVisXChart } from '../VisXChart'
import { formatChartTimestamp } from './dateFormat'

const timestampAccessor = (v: Value) => v.timestamp.valueOf()

export function EventsPerVisitorContent({ action, granularity, showTable }: InsightsChartProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: currentUser } = useCurrentUser()
  const { dateRange } = useDateRangeContext()

  const { isLoading, error, values, xDomain, yDomain } = useEventsPerVisitorComparison(
    subscriptionId,
    currentUser,
    dateRange,
    granularity
  )

  const { handlePointer, tooltipIndex, position } = useTimelineIndexer(values, timestampAccessor)
  const tooltipData = useEventsPerVisitorTooltipData(tooltipIndex, values, dateRange)

  return (
    <ChartLayout>
      <Header action={action} />
      {showTable ? (
        <ChartTable isLoading={isLoading} values={values} granularity={granularity} />
      ) : (
        <>
          <Legend />
          <ChartGraph>
            {isLoading ? <ChartLoading /> : null}
            {error ? <ChartError /> : null}
            {!isLoading && !error && values != null && values?.length === 0 ? <ChartNoData /> : null}
            {isLoading || error || values?.length === 0 ? null : (
              <ResponsiveVisXChart
                timezone={currentUser?.timezone}
                xDomain={xDomain}
                yDomain={yDomain}
                margin={{ top: 0, right: 0, bottom: 20, left: 20 }}
                svgClassName='overflow-visible'
                handlePointer={handlePointer}
              >
                {({ xScale, yScale }) => (
                  <>
                    {/* To make sure that the lines don't go under the axis, apply a cliping path around the Lines. */}
                    <Group clipPath='url(#content)'>
                      <LineWithShadow
                        data={values}
                        curve={curveMonotoneX}
                        x={(point) => xScale(point.timestamp)}
                        y={(point) => yScale(point.visitorCount ?? 0)}
                        stroke='hsl(var(--fpds-color-purple-7))'
                        strokeWidth={1.5}
                        strokeOpacity={1}
                      />

                      <LineWithShadow
                        data={values}
                        curve={curveMonotoneX}
                        x={(point) => xScale(point.timestamp)}
                        y={(point) => yScale(point.eventCount ?? 0)}
                        stroke='hsl(var(--fpds-color-orange-7))'
                        strokeWidth={1.5}
                        strokeOpacity={1}
                      />
                    </Group>
                    {tooltipData != null ? (
                      <>
                        <CircleMark
                          cx={tooltipData.periodStart ? xScale(tooltipData.periodStart) : undefined}
                          cy={yScale(tooltipData.visitorCount ?? 0)}
                          fill='hsl(var(--fpds-color-purple-7))'
                        />
                        <CircleMark
                          cx={tooltipData.periodStart ? xScale(tooltipData.periodStart) : undefined}
                          cy={yScale(tooltipData.eventCount ?? 0)}
                          fill='hsl(var(--fpds-color-orange-7))'
                        />
                      </>
                    ) : null}
                  </>
                )}
              </ResponsiveVisXChart>
            )}
            {tooltipData != null ? (
              <TooltipContainer position={position}>
                <TooltipHeader>
                  <span className='font-mono'>{formatChartTimestamp(tooltipData.periodStart, granularity)}</span>
                </TooltipHeader>
                <TooltipContent className='grid grid-cols-[auto_1fr_auto] gap-x-3 gap-y-2 items-center justify-between '>
                  <OrangeMarker />
                  <span className='text-gray-800'>API calls</span>
                  <span className='ml-4 font-mono font-medium text-right'>
                    {tooltipData.eventCount != null ? formatNum(tooltipData.eventCount) : 0}
                  </span>

                  <PurpleMarker />
                  <span className='text-gray-800'>Unique visitors</span>
                  <span className='ml-4 font-mono font-medium text-right'>
                    {tooltipData.visitorCount != null ? formatNum(tooltipData.visitorCount) : 0}
                  </span>

                  <div className='col-span-3 border-b border-b-gray-200' />

                  <span className='text-gray-800 col-span-2'>Ratio</span>
                  <span className='font-mono font-medium text-right'>
                    {tooltipData.eventCount != null && tooltipData.visitorCount != null && tooltipData.visitorCount != 0
                      ? formatNum(tooltipData.eventCount / tooltipData.visitorCount, true)
                      : 0}
                  </span>
                </TooltipContent>
              </TooltipContainer>
            ) : null}
          </ChartGraph>
        </>
      )}
    </ChartLayout>
  )
}
function Header({ action }: Pick<InsightsChartProps, 'action'>) {
  const { data: stats, isLoading: statsLoading } = useInsightsStats()
  const valueTotal = stats?.find((s) => s.type === SubscriptionStatistic.EventsPerVisitor)?.data as
    | SubscriptionStatisticValue[SubscriptionStatistic.EventsPerVisitor]
    | undefined

  return (
    <ChartHeader>
      <ChartTitle
        title='Events per visitor'
        info='Shows the ratio of identification requests per unique visitor ID. Spikes in ratio value may indicate that you are making excessive API calls, and you may need to optimize your implementation.'
        action={action}
      />
      <ChartValue>
        <ChartMainValue>
          {valueTotal?.currentPeriod != null ? (
            formatNum(valueTotal.currentPeriod, true)
          ) : (
            <Skeleton width={80} height={28} />
          )}
        </ChartMainValue>
        <ChartValueComparison
          isLoading={statsLoading}
          value={valueTotal?.currentPeriod}
          previousValue={valueTotal?.prevPeriod}
        />
      </ChartValue>
    </ChartHeader>
  )
}

function Legend() {
  return (
    <ChartLegend>
      <ChartLegendItem>
        <OrangeMarker />
        API calls
      </ChartLegendItem>
      <ChartLegendItem>
        <PurpleMarker />
        Unique visitors
      </ChartLegendItem>
    </ChartLegend>
  )
}

function ChartTable({
  isLoading,
  values,
  granularity,
}: {
  isLoading?: boolean
  values: Value[]
  granularity?: UsageCounterPeriod
}) {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell width='25%'>Time</TableCell>
          <TableCell width='25%' align='right'>
            API calls
          </TableCell>
          <TableCell width='25%' align='right'>
            Unique visitors
          </TableCell>
          <TableCell width='25%' align='right'>
            Ratio
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody columnCount={4} isLoading={isLoading}>
        <TableBodyData>
          {values.map((v) => (
            <TableRow key={v.timestamp.valueOf()}>
              <TableCell>{formatChartTimestamp(v.timestamp, granularity)}</TableCell>
              <TableCell align='right'>{v.eventCount != null ? formatNum(v.eventCount) : null}</TableCell>
              <TableCell align='right'>{v.visitorCount != null ? formatNum(v.visitorCount) : null}</TableCell>
              <TableCell align='right'>
                {v.eventCount != null && v.visitorCount != null && v.visitorCount != 0
                  ? formatNum(v.eventCount / v.visitorCount, true)
                  : 0}
              </TableCell>
            </TableRow>
          ))}
        </TableBodyData>
      </TableBody>
    </Table>
  )
}
