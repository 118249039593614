/**
 * This is a temporary file.
 * In future, should be exported directly from `@fpjs-mgmt/api` package
 */

import {
  AccountSettings,
  AccountTransferCreate,
  AccountTransferRequest,
  ApiKey,
  ApplicationFeature,
  AppProduct,
  AuthTokens,
  AuthTokensWithUserContext,
  BillingInfo,
  BotdCounterType,
  BotdVisitDetails,
  BotdVisitsFilter,
  BroadcastUnsubscribedTopics,
  ClientSDKPerformanceStatsResponse,
  CloudflareApiTokenValidationResponse,
  CloudflareDeploymentStatus,
  CloudflareDomainsResponse,
  CloudflareIntegration,
  CloudflareSubdomainsResponse,
  CloudFrontIntegration,
  ContactSupportData,
  EditUserFormData,
  EmailNotificationsSettings,
  EmailNotificationsUpdateBody,
  EmailType,
  ExpandedSubscription,
  GetDistributionsQuery,
  GetSubscriptionStatsQuery,
  IdentificationEventExportBody,
  Invoice,
  InvoiceListItem,
  LoginResponse,
  NoSqlPagination,
  NoSqlReversePagination,
  Notification,
  OAuthProvider,
  PaginatedBotdVisitsResponse,
  PaginatedVisitsResponse,
  PaginatedWebhookEventsResponse,
  PaginationParams,
  PasswordStrengthResponse,
  PaymentMethod,
  PricingData,
  Product,
  ProxyIntegrationRequests,
  ReadmeLogin,
  SdkHealth,
  SdkHealthStatus,
  ServiceStatusResponse,
  SortParams,
  SSLCertificate,
  SSOLoginResponse,
  SsoStatusResponse,
  StripePaymentMethod,
  SubscriptionDistributionChartResponse,
  SubscriptionHealth,
  SubscriptionIntegrationStatus,
  SubscriptionStatsResponse,
  SubscriptionTier,
  SubscriptionType,
  SubscriptionWebhook,
  SuspectScoreResponse,
  TrafficRule,
  TrafficRuleCreatePayload,
  TrafficRuleType,
  UnsubscribeTypes,
  UpdatePasswordFormData,
  UpdateSuspectScoreBody,
  UsageChartData,
  UsageCounterPeriod,
  UsageCounterType,
  User,
  UserConsentData,
  UserConsentModel,
  UserContext,
  UserFeedbackData,
  UserInvitation,
  UserSettings,
  UserStats,
  UserSubscriptionSettings,
  Visit,
  VisitsFilter,
  WebhookEvent,
  WebhookEventsFilter,
  WorkerData,
  WorkerDeploymentRequestResult,
  WorkspaceEnvironment,
} from 'models'
import { PlatformEvent } from 'models/event'

import { ValueOf } from '../helpers/types'
import { SmartSignalResponse } from '../models/smartSignal'
import { API_URL } from './env'

const BASE_URL = API_URL

/**
 * API errors could be related to a param (email) or to an entity (subscription).
 * @example
 * {
 *   code: 'value_invalid' | 'value_not_found' | 'value_not_exists'
 *   param: 'email'
 *   message: 'Email is not valid'
 * }
 */
export type GenericError = {
  code: string
  param?: string
  message?: string
}

export type SuccessResponse<TData, TMeta = undefined> = { ok: true; data: TData; meta?: TMeta }
export type ErrorResponse = { ok: false; error?: GenericError }
/**
 * All API responses has a generic response structure.
 * @example
 * { ok: true, data: { id: 123 } }
 * { ok: true, data: { id: 123 }, meta: {count: 10} }
 * { ok: false, error: { message: 'Some Error' } }
 */
export type GenericResponse<TData, TMeta = undefined> = SuccessResponse<TData, TMeta> | ErrorResponse

/**
 * Some API responses include optional metadata in their response
 * @example
 * {ok: true, data: {id: 123}, meta: {count: 10}}
 */
// export type WithMetaResponse<T, M = undefined> = [T, M?]

type EndpointDescription = {
  method: string
  url: string
  authorization?: boolean
  propagateErrorMessage?: boolean
  isUnwrapped?: boolean
}

interface TokenUpdateRequest extends Pick<ApiKey, 'rateLimit' | 'name' | 'description'> {
  disabled: boolean
}

export const API: Record<EndpointList, EndpointDescription> = {
  logIn: {
    method: 'POST',
    url: '/login',
  },
  logOut: {
    method: 'POST',
    url: '/logout',
    authorization: true,
  },
  exchangeRefreshToken: {
    method: 'POST',
    url: '/refresh_token/exchange',
  },
  ssoAuth: {
    method: 'POST',
    url: '/sso/auth',
  },
  ssoCallback: {
    method: 'POST',
    url: '/sso/callback',
  },
  oAuth: {
    method: 'GET',
    url: '/sso/oauth',
  },
  signupIntentCreate: {
    method: 'POST',
    url: '/signup',
  },
  signupIntentConfirm: {
    method: 'POST',
    url: '/signup/confirm',
  },
  signupConfirmResend: {
    method: 'POST',
    url: '/signup/confirm/resend',
    authorization: true,
  },
  passwordResetCreate: {
    method: 'POST',
    url: '/password_resets',
  },
  passwordResetGet: {
    url: '/password_resets/:id',
    method: 'GET',
  },
  passwordResetConfirm: {
    url: '/password_resets/:id',
    method: 'PUT',
  },
  subscriptionStart: {
    url: '/subscriptions/start',
    method: 'POST',
    authorization: true,
  },
  paymentMethods: {
    url: '/payment_methods',
    method: 'GET',
    authorization: true,
  },
  paymentMethodCreate: {
    url: '/payment_methods',
    method: 'POST',
    authorization: true,
  },
  paymentMethodDelete: {
    url: '/payment_methods/:id',
    method: 'DELETE',
    authorization: true,
  },
  paymentMethodMakeDefault: {
    url: '/payment_methods/:id/make-default',
    method: 'PUT',
    authorization: true,
  },
  subscriptions: {
    url: '/subscriptions',
    method: 'GET',
    authorization: true,
  },
  subscriptionGet: {
    url: '/subscriptions/:id',
    method: 'GET',
    authorization: true,
  },
  subscriptionCreate: {
    url: '/subscriptions',
    method: 'POST',
    authorization: true,
  },
  subscriptionUpdate: {
    url: '/subscriptions/:id',
    method: 'PATCH',
    authorization: true,
  },
  subscriptionDelete: {
    url: '/subscriptions/:id',
    method: 'DELETE',
    authorization: true,
  },
  subscriptionUpgrade: {
    method: 'POST',
    url: '/subscriptions/:subscriptionId/upgrade',
    authorization: true,
  },
  subscriptionIntegrationUsageStats: {
    method: 'GET',
    url: '/subscriptions/:subscriptionId/integrations/platform/:integrationName/stats',
    authorization: true,
  },
  subscriptionSdkUsage: {
    method: 'GET',
    url: '/subscriptions/:subscriptionId/sdk-usage',
    authorization: true,
  },
  subscriptionSdkHealthStatus: {
    method: 'GET',
    url: '/subscriptions/:subscriptionId/sdk-usage/health-status',
    authorization: true,
  },
  subscriptionStats: {
    method: 'GET',
    url: '/subscriptions/:subscriptionId/stats',
    authorization: true,
    isUnwrapped: false,
  },
  subscriptionDistributions: {
    method: 'GET',
    url: '/subscriptions/:subscriptionId/stats/distribution',
    authorization: true,
    isUnwrapped: false,
  },
  subscriptionCancellationSurvey: {
    method: 'POST',
    url: '/subscriptions/:subscriptionId/cancelation-survey-result',
    authorization: true,
  },
  subscriptionTrialSelfExtend: {
    method: 'POST',
    url: '/subscriptions/:subscriptionId/extend_trial_self',
    authorization: true,
  },
  subscriptionFeatures: {
    method: 'GET',
    url: '/subscriptions/:subscriptionId/features',
    authorization: true,
  },
  subscriptionFeatureToggle: {
    method: 'POST',
    url: '/subscriptions/:subscriptionId/features/toggle',
    authorization: true,
  },
  encryptionKeys: {
    method: 'GET',
    url: '/subscriptions/:subscriptionId/encryption',
    authorization: true,
  },
  encryptionKeyDelete: {
    method: 'DELETE',
    url: '/subscriptions/:subscriptionId/encryption/:keyId',
    authorization: true,
  },
  encryptionKeyCreate: {
    method: 'POST',
    url: '/subscriptions/:subscriptionId/encryption',
    authorization: true,
  },
  encryptionKeyActivate: {
    method: 'POST',
    url: '/subscriptions/:subscriptionId/encryption/:keyId/activate',
    authorization: true,
  },
  encryptionKeyDeactivate: {
    method: 'POST',
    url: '/subscriptions/:subscriptionId/encryption/:keyId/deactivate',
    authorization: true,
  },
  encryptionKeyUpdate: {
    method: 'PUT',
    url: '/subscriptions/:subscriptionId/encryption/:keyId',
    authorization: true,
  },
  trialCancel: {
    method: 'DELETE',
    url: '/subscriptions/:subscriptionId/trials',
    authorization: true,
  },
  certificates: {
    url: '/subscriptions/:subscriptionId/certificates',
    method: 'GET',
    authorization: true,
  },
  certificateGet: {
    url: '/subscriptions/:subscriptionId/certificates/:id',
    method: 'GET',
    authorization: true,
  },
  certificateCreate: {
    url: '/subscriptions/:subscriptionId/certificates/',
    method: 'POST',
    authorization: true,
  },
  certificateDelete: {
    url: '/subscriptions/:subscriptionId/certificates/:id',
    method: 'DELETE',
    authorization: true,
  },
  tokens: {
    url: '/subscriptions/:subscriptionId/tokens',
    method: 'Get',
    authorization: true,
  },
  tokenCreate: {
    url: '/subscriptions/:subscriptionId/tokens',
    method: 'POST',
    authorization: true,
  },
  tokenUpdate: {
    url: '/subscriptions/:subscriptionId/tokens/:id',
    method: 'PUT',
    authorization: true,
  },
  tokenDelete: {
    url: '/subscriptions/:subscriptionId/tokens/:id',
    method: 'DELETE',
    authorization: true,
  },
  subscriptionWebhooks: {
    url: '/subscriptions/:subscriptionId/webhooks',
    method: 'GET',
    authorization: true,
  },
  subscriptionWebhookGet: {
    url: '/subscriptions/:subscriptionId/webhooks/:id',
    method: 'GET',
    authorization: true,
  },
  subscriptionWebhookCreate: {
    url: '/subscriptions/:subscriptionId/webhooks',
    method: 'POST',
    authorization: true,
  },
  subscriptionWebhookUpdate: {
    url: '/subscriptions/:subscriptionId/webhooks/:id',
    method: 'PUT',
    authorization: true,
  },
  subscriptionWebhookDelete: {
    url: '/subscriptions/:subscriptionId/webhooks/:id',
    method: 'DELETE',
    authorization: true,
  },
  unsubscribeUserFromEmail: {
    url: '/unsubscribe_email',
    method: 'POST',
    authorization: false,
  },
  unsubscribeUserFromNewsletter: {
    url: '/newsletter/unsubscribe/:type/:token/:topic',
    method: 'POST',
    authorization: false,
  },
  trafficRules: {
    url: '/subscriptions/:subscriptionId/traffic_rules',
    method: 'GET',
    authorization: true,
  },
  trafficRuleCreate: {
    url: '/subscriptions/:subscriptionId/traffic_rules',
    method: 'POST',
    authorization: true,
  },
  trafficRuleDelete: {
    url: '/subscriptions/:subscriptionId/traffic_rules/:trafficRuleId',
    method: 'DELETE',
    authorization: true,
  },
  trafficRuleUpdate: {
    url: '/subscriptions/:subscriptionId/traffic_rules/:trafficRuleId',
    method: 'PUT',
    authorization: true,
  },
  trafficRuleBulkUpdate: {
    url: '/subscriptions/:subscriptionId/traffic_rules/bulk',
    method: 'PUT',
    authorization: true,
  },
  usageCounters: {
    url: '/subscriptions/:subscriptionId/usage_counters',
    method: 'GET',
    authorization: true,
  },
  timeline: {
    url: '/subscriptions/:subscriptionId/stats/timeline',
    method: 'GET',
    authorization: true,
  },
  accountSettings: {
    url: '/customers/me',
    method: 'GET',
    authorization: true,
  },
  accountSettingsUpdate: {
    url: '/customers/me',
    method: 'PUT',
    authorization: true,
  },
  userSubscriptionSettings: {
    url: '/subscriptions/:subscriptionId/user/settings/:settingsName',
    method: 'GET',
    authorization: true,
  },
  userSubscriptionSettingsUpdate: {
    url: '/subscriptions/:subscriptionId/user/settings',
    method: 'POST',
    authorization: true,
  },
  userSettings: {
    url: '/users/me/settings/:settingsName',
    method: 'GET',
    authorization: true,
  },
  userSettingsUpdate: {
    url: '/users/me/settings',
    method: 'POST',
    authorization: true,
  },
  notifications: {
    url: '/notifications',
    method: 'GET',
    authorization: true,
  },
  // TODO could be deleted
  notificationsDelete: {
    url: '/notifications/:type',
    method: 'DELETE',
    authorization: true,
  },
  userContextGet: {
    url: '/users/me/context',
    method: 'GET',
    authorization: true,
  },
  userStatsGet: {
    url: '/users/me/stats',
    method: 'GET',
    authorization: true,
  },
  invoices: {
    url: '/invoices',
    method: 'GET',
    authorization: true,
  },
  invoiceGet: {
    url: '/invoices/:id',
    method: 'GET',
    authorization: true,
  },
  onboardingSkip: {
    url: '/onboarding/skip',
    method: 'POST',
    authorization: true,
  },
  invoiceUpcomingGet: {
    url: '/subscriptions/:subscriptionId/invoices/upcoming',
    method: 'GET',
    authorization: true,
  },
  healthGet: {
    url: '/subscriptions/:subscriptionId/health',
    method: 'GET',
    authorization: true,
  },
  smartSignalsGet: {
    url: '/subscriptions/:subscriptionId/stats/smart-signals',
    method: 'GET',
    authorization: true,
  },
  usersGet: {
    url: '/users',
    method: 'GET',
    authorization: true,
  },
  userUpdate: {
    url: '/users/:userId',
    method: 'PUT',
    authorization: true,
  },
  userConsentUpdate: {
    url: '/users/me/consent',
    method: 'PUT',
    authorization: true,
  },
  userDelete: {
    url: '/users/:userId',
    method: 'DELETE',
    authorization: true,
  },
  userFeedbackGet: {
    url: '/users/me/feedback',
    method: 'GET',
    authorization: true,
  },
  userFeedbackUpdate: {
    url: '/users/me/feedback',
    method: 'PUT',
    authorization: true,
  },
  invitationConfirm: {
    url: '/users/invitations/confirm/:id',
    method: 'POST',
    authorization: false,
  },
  invitationValidate: {
    url: '/users/invitations/validate/:id',
    method: 'GET',
    authorization: false,
  },
  readmeLogin: {
    url: '/readme_login',
    method: 'POST',
    authorization: true,
  },
  integrationStatusGet: {
    url: '/subscriptions/:subscriptionId/integration_status',
    method: 'GET',
    authorization: true,
  },
  integrationStepSkip: {
    url: '/subscriptions/:subscriptionId/integration_status/skip',
    method: 'POST',
    authorization: true,
  },
  passwordStrength: {
    url: '/signup/password_strength',
    method: 'POST',
    authorization: false,
  },
  impersonate: {
    url: '/impersonation/exchange',
    method: 'POST',
    authorization: false,
  },
  visitsGet: {
    url: '/subscriptions/:subscriptionId/visits',
    method: 'GET',
    authorization: true,
  },
  visitGet: {
    url: '/subscriptions/:subscriptionId/visits/:requestId',
    method: 'GET',
    authorization: true,
  },
  visitorGet: {
    url: '/subscriptions/:subscriptionId/visitors/:visitorId',
    method: 'GET',
    authorization: true,
  },
  pricesGet: {
    url: '/prices',
    method: 'GET',
    authorization: false,
  },
  webhookEventsGet: {
    url: '/subscriptions/:subscriptionId/webhooks/:webhookId/events',
    method: 'GET',
    authorization: true,
  },
  webhookEventGet: {
    url: '/subscriptions/:subscriptionId/webhooks/:webhookId/events/:requestId',
    method: 'GET',
    authorization: true,
  },
  visitorWebhookEventsGet: {
    url: '/subscriptions/:subscriptionId/webhooks/:webhookId/visitors/:visitorId',
    method: 'GET',
    authorization: true,
  },
  webhookSendTestEvent: {
    url: '/subscriptions/:subscriptionId/webhooks/:webhookId/validation',
    method: 'POST',
    authorization: true,
  },
  webhookEventResend: {
    url: '/subscriptions/:subscriptionId/webhooks/:webhookId/events/:requestId/resend',
    method: 'POST',
    authorization: true,
  },
  passwordUpdate: {
    url: '/password_update',
    method: 'POST',
    authorization: true,
  },
  currentUserGet: {
    url: '/users/me',
    method: 'GET',
    authorization: true,
  },
  emailNotificationSettingsGet: {
    url: '/users/me/email_notification_settings',
    method: 'GET',
    authorization: true,
  },
  emailNotificationSettingsUpdate: {
    url: '/users/me/email_notification_settings',
    method: 'PUT',
    authorization: true,
  },
  enableEmailNotifications: {
    url: '/users/me/enable_emails',
    method: 'POST',
    authorization: true,
  },
  // TODO could be deleted
  sendToDeveloper: {
    url: '/onboarding/send_to_developer',
    method: 'POST',
    authorization: true,
  },
  contactSupport: {
    url: '/hubspot/user_support',
    method: 'POST',
    authorization: true,
  },
  productFeedback: {
    url: '/customers/product-feedback',
    method: 'POST',
    authorization: true,
  },
  cloudflareIntegrationGet: {
    url: '/integrations/customer/cf/:subscriptionId',
    method: 'GET',
    authorization: true,
  },
  updateCloudflareToken: {
    url: '/integrations/customer/cf/update_token',
    method: 'PATCH',
    authorization: true,
  },
  generateWorkerName: {
    url: '/integrations/customer/cf/worker_name',
    method: 'POST',
    authorization: true,
  },
  cloudflareVerifyToken: {
    url: '/integrations/customer/cf/verify_token',
    method: 'POST',
    authorization: true,
  },
  startDeploymentWorkerCF: {
    url: '/integrations/customer/cf/deploy_worker',
    method: 'POST',
    authorization: true,
  },
  cloudflareDeploymentStatus: {
    url: '/integrations/customer/cf/deployment_status/:id',
    method: 'GET',
    authorization: true,
  },
  cloudflareDomainsGet: {
    url: '/integrations/customer/cf/domains',
    method: 'POST',
    authorization: true,
  },
  cloudflareSubdomainsGet: {
    url: '/integrations/customer/cf/subdomains',
    method: 'POST',
    authorization: true,
  },
  removeCloudflareIntegration: {
    url: '/integrations/customer/cf/:subscriptionId/:id',
    method: 'DELETE',
    authorization: true,
  },
  cloudFrontIntegrationGet: {
    url: '/integrations/customer/cloudfront/:subscriptionId',
    method: 'GET',
    authorization: true,
  },
  cloudFrontIntegrationCreate: {
    url: '/integrations/customer/cloudfront/:subscriptionId',
    method: 'POST',
    authorization: true,
    propagateErrorMessage: true,
  },
  cloudFrontIntegrationUpdate: {
    url: '/integrations/customer/cloudfront/:subscriptionId',
    method: 'PUT',
    authorization: true,
    propagateErrorMessage: true,
  },
  cloudFrontIntegrationDelete: {
    url: '/integrations/customer/cloudfront/:subscriptionId',
    method: 'DELETE',
    authorization: true,
  },
  botdVisitsGet: {
    url: '/subscriptions/:subscriptionId/botd_visits',
    method: 'GET',
    authorization: true,
  },
  botdVisitGet: {
    url: '/subscriptions/:subscriptionId/botd/visits/:requestId',
    method: 'GET',
    authorization: true,
  },
  subscriptionDowngrade: {
    url: '/subscriptions/:subscriptionId/downgrade',
    method: 'POST',
    authorization: true,
  },
  billingInfoGet: {
    url: '/customers/billing_info',
    method: 'GET',
    authorization: true,
  },
  billingInfoUpdate: {
    url: '/customers/billing_info',
    method: 'POST',
    authorization: true,
  },
  accountTransferGet: {
    url: '/customers/account_transfer',
    method: 'GET',
    authorization: true,
  },
  accountTransferIntent: {
    url: '/customers/account_transfer/intent',
    method: 'POST',
    authorization: true,
  },
  accountTransferCreate: {
    url: '/customers/account_transfer',
    method: 'POST',
    authorization: true,
  },
  accountTransferDelete: {
    url: '/customers/account_transfer/:id',
    method: 'DELETE',
    authorization: true,
  },
  accountTransferConfirm: {
    url: '/customers/account_transfer/confirm',
    method: 'GET',
    authorization: true,
  },
  eventGet: {
    url: '/subscriptions/:subscriptionId/events/:requestId',
    method: 'GET',
    authorization: true,
  },
  identificationEventExport: {
    url: '/subscriptions/:subscriptionId/visits/export',
    method: 'POST',
    authorization: true,
  },
  clientSdkPerformance: {
    url: '/subscriptions/:subscriptionId/client-sdk-performance',
    method: 'GET',
    authorization: true,
  },
  serviceStatus: {
    url: '/service-status',
    method: 'GET',
    authorization: false,
  },
  sendOneTimePassword: {
    url: '/emails/one_time_password/',
    method: 'POST',
    authorization: true,
  },
  deleteAccount: {
    url: '/customers/me',
    method: 'DELETE',
    authorization: true,
  },
  workspaceEnvironmentDelete: {
    url: '/subscriptions/:subscriptionId/workspace_environments/:workspaceEnvironmentId',
    method: 'DELETE',
    authorization: true,
  },
  workspaceEnvironmentCreate: {
    url: '/subscriptions/:subscriptionId/workspace_environments',
    method: 'POST',
    authorization: true,
  },
  workspaceEnvironments: {
    url: '/subscriptions/:subscriptionId/workspace_environments',
    method: 'GET',
    authorization: true,
  },
  suspectScores: {
    url: '/subscriptions/:subscriptionId/suspect_score',
    method: 'GET',
    authorization: true,
  },
  suspectScoresUpdate: {
    url: '/subscriptions/:subscriptionId/suspect_score',
    method: 'PUT',
    authorization: true,
  },
  bdrMeetingScheduled: {
    url: '/customers/bdr-meeting',
    method: 'POST',
    authorization: true,
  },
  integrationRequest: {
    url: '/customers/integration-request',
    method: 'POST',
    authorization: true,
  },
  contactSalesEnterpriseClosure: {
    url: '/users/record-contact-sales-enterprise-form-closure',
    method: 'POST',
    authorization: true,
  },
  getInvitations: {
    url: '/users/invitations',
    method: 'GET',
    authorization: true,
  },
  getMyInvitations: {
    url: '/users/me/invitations',
    method: 'GET',
    authorization: true,
  },
  confirmTransfer: {
    url: '/users/invitations/confirm-transfer',
    method: 'POST',
    authorization: true,
  },
  createInvitation: {
    url: '/users/invitations',
    method: 'POST',
    authorization: true,
  },
  deleteInvitation: {
    url: '/users/invitations/:id',
    method: 'DELETE',
    authorization: true,
  },
  updateInvitation: {
    url: '/users/invitations/:id',
    method: 'POST',
    authorization: true,
  },
}

export type APITypesMap = {
  logIn: {
    params: {}
    queryParams: undefined
    body: {
      email: string
      password: string
      fpjsVisitorId?: string
    }
    response: LoginResponse
    meta: undefined
    expandable: undefined
  }
  logOut: {
    params: {}
    queryParams: undefined
    body: Pick<AuthTokens, 'refreshToken'>
    response: {}
    meta: undefined
    expandable: undefined
  }
  exchangeRefreshToken: {
    params: {}
    queryParams: {}
    body: Pick<AuthTokens, 'refreshToken'>
    response: AuthTokens
    meta: undefined
    expandable: undefined
  }
  ssoAuth: {
    params: {}
    queryParams: undefined
    body: {
      email: string
      state?: string
    }
    response: SsoStatusResponse
    meta: undefined
    expandable: undefined
  }
  ssoCallback: {
    params: {}
    queryParams: undefined
    body: {
      code: string
      provider?: OAuthProvider
    } & Partial<UserConsentModel>
    response: SSOLoginResponse
    meta: undefined
    expandable: undefined
  }
  oAuth: {
    params: undefined
    queryParams: undefined
    body: undefined
    response: Record<OAuthProvider, string>
    meta: undefined
    expandable: undefined
  }
  passwordResetCreate: {
    params: {}
    queryParams: undefined
    body: {
      email: string
    }
    response: undefined
    meta: undefined
    expandable: undefined
  }
  signupIntentCreate: {
    params: {}
    queryParams: undefined
    body: {
      name: string
      email: string
      password: string
      fpjsVisitorId?: string
      botdRequestId?: string
      utmInfo: Record<string, string>
      landingPage?: string
      visitedPages?: string[]
      signupSource?: string
    }
    response: AuthTokensWithUserContext
    meta: undefined
    expandable: undefined
  }
  signupIntentConfirm: {
    params: {}
    queryParams: undefined
    body: {
      signupIntent: string
      confirmationCode: string
    }
    response: AuthTokensWithUserContext
    meta: undefined
    expandable: undefined
  }
  signupConfirmResend: {
    params: {}
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  passwordResetGet: {
    params: {
      id: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  passwordResetConfirm: {
    params: {
      id: string
    }
    queryParams: undefined
    body: {
      password: string
    }
    response: undefined
    meta: undefined
    expandable: undefined
  }
  subscriptionStart: {
    params: {}
    queryParams: undefined
    body: {
      name: string
      domain?: string
      regionCode: string
      fraudType?: string | null
      otherFraudType?: string | null
      jobLevel?: string
      jobFunction?: string
      privacyPolicy: boolean
      termsOfService: boolean
      timezone: string
      visitorLocation?: string
      challengeToSolve?: string
    }
    response: {
      id: string
    }
    meta: undefined
    expandable: undefined
  }
  paymentMethods: {
    params: {}
    queryParams: undefined
    body: undefined
    response: PaymentMethod[]
    meta: undefined
    expandable: undefined
  }
  paymentMethodCreate: {
    params: {}
    queryParams: undefined
    body: Pick<
      PaymentMethod,
      'cardBrand' | 'cardCountry' | 'cardExpMonth' | 'cardExpYear' | 'cardFunding' | 'cardLast4' | 'stripeId'
    > & { cardholderName: string }
    response: PaymentMethod
    meta: undefined
    expandable: undefined
  }
  paymentMethodDelete: {
    params: {
      id: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  paymentMethodMakeDefault: {
    params: {
      id: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  subscriptions: {
    params: {}
    queryParams: undefined
    body: undefined
    response: ExpandedSubscription[]
    meta: undefined
    expandable: 'usageQuota' | 'latestTier' | 'promotions' | 'discounts'
  }
  subscriptionGet: {
    params: {
      id: string
    }
    queryParams: undefined
    body: undefined
    response: ExpandedSubscription
    meta: undefined
    expandable: 'usageQuota' | 'upcomingAmount' | 'projectedUsage' | 'latestTier' | 'promotions' | 'discounts'
  }
  subscriptionCreate: {
    params: {}
    queryParams: undefined
    body: {
      name: string
      domain?: string
      regionCode: string
      type: SubscriptionType
      paymentMethod?: StripePaymentMethod
      tier?: SubscriptionTier
      stripeBillingCycleAnchor?: number
    }
    response: {
      id: string
    }
    meta: undefined
    expandable: undefined
  }
  subscriptionUpdate: {
    params: {
      id: string
    }
    queryParams: undefined
    body: { name?: string; defaultPaymentMethodId?: string; domain?: string | null }
    response: ExpandedSubscription
    meta: undefined
    expandable: undefined
  }
  subscriptionDelete: {
    params: {
      id: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  subscriptionUpgrade: {
    params: { subscriptionId: string }
    queryParams: undefined
    body: {
      paymentMethod: StripePaymentMethod
      promotionId?: number
      tier?: SubscriptionTier
    }
    response: {}
    meta: undefined
    expandable: undefined
  }
  subscriptionIntegrationUsageStats: {
    params: {
      subscriptionId: string
      integrationName: string
    }
    queryParams: undefined
    body: undefined
    response: ProxyIntegrationRequests
    meta: undefined
    expandable: undefined
  }
  subscriptionSdkUsage: {
    params: { subscriptionId: string }
    queryParams: undefined
    body: undefined
    response: SdkHealth
    meta: undefined
    expandable: undefined
  }
  subscriptionSdkHealthStatus: {
    params: { subscriptionId: string }
    queryParams: undefined
    body: undefined
    response: SdkHealthStatus
    meta: undefined
    expandable: undefined
  }
  subscriptionStats: {
    params: { subscriptionId: string }
    queryParams: GetSubscriptionStatsQuery
    body: undefined
    response: SubscriptionStatsResponse
    meta: undefined
    expandable: undefined
  }
  subscriptionDistributions: {
    params: { subscriptionId: string }
    queryParams: GetDistributionsQuery
    body: undefined
    response: SubscriptionDistributionChartResponse
    meta: undefined
    expandable: undefined
  }
  subscriptionCancellationSurvey: {
    params: { subscriptionId: string }
    queryParams: undefined
    body: {
      reasons: string[]
      selectedCompetitor?: string
      message: string
    }
    response: {
      ok: boolean
      error: any
    }
    meta: undefined
    expandable: undefined
  }
  subscriptionTrialSelfExtend: {
    params: { subscriptionId: string }
    queryParams: undefined
    body: undefined
    response: {
      ok: boolean
      error: any
      trialEndAt: string
    }
    meta: undefined
    expandable: undefined
  }
  subscriptionFeatures: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: ApplicationFeature[]
    meta: undefined
    expandable: undefined
  }
  subscriptionFeatureToggle: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: {
      featureName: string
    }
    response: undefined
    meta: undefined
    expandable: undefined
  }
  encryptionKeys: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: {
      keys: ApiKey[]
    }
    meta: undefined
    expandable: undefined
  }
  encryptionKeyDelete: {
    params: {
      subscriptionId: string
      keyId: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  encryptionKeyActivate: {
    params: {
      subscriptionId: string
      keyId: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  encryptionKeyDeactivate: {
    params: {
      subscriptionId: string
      keyId: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  encryptionKeyCreate: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: Pick<ApiKey, 'description' | 'name'>
    response: ApiKey
    meta: undefined
    expandable: undefined
  }
  encryptionKeyUpdate: {
    params: {
      subscriptionId: string
      keyId: string
    }
    queryParams: undefined
    body: Pick<ApiKey, 'description' | 'name'>
    response: ApiKey
    meta: undefined
    expandable: undefined
  }
  trialCancel: {
    params: { subscriptionId: string }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  certificates: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: SSLCertificate[]
    meta: undefined
    expandable: undefined
  }
  certificateGet: {
    params: {
      id: string
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: SSLCertificate
    meta: undefined
    expandable: 'subscription'
  }
  certificateCreate: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: {
      domain: string
    }
    response: SSLCertificate
    meta: undefined
    expandable: undefined
  }
  certificateDelete: {
    params: {
      id: string
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  tokens: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: ApiKey[]
    meta: undefined
    expandable: undefined
  }
  tokenCreate: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: Pick<ApiKey, 'type' | 'description' | 'name'>
    response: ApiKey
    meta: undefined
    expandable: undefined
  }
  tokenUpdate: {
    params: {
      id: string
      subscriptionId: string
    }
    queryParams: undefined
    body: TokenUpdateRequest
    response: boolean
    meta: undefined
    expandable: undefined
  }
  tokenDelete: {
    params: {
      id: string
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  subscriptionWebhooks: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: SubscriptionWebhook[]
    meta: undefined
    expandable: undefined
  }
  subscriptionWebhookGet: {
    params: {
      id: string
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: SubscriptionWebhook
    meta: undefined
    expandable: undefined
  }
  subscriptionWebhookCreate: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: Pick<SubscriptionWebhook, 'url'>
    response: Pick<SubscriptionWebhook, 'id'>
    meta: undefined
    expandable: undefined
  }
  subscriptionWebhookUpdate: {
    params: {
      id: string
      subscriptionId: string
    }
    queryParams: undefined
    body: SubscriptionWebhook
    response: undefined
    meta: undefined
    expandable: undefined
  }
  subscriptionWebhookDelete: {
    params: {
      id: string
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  unsubscribeUserFromEmail: {
    params: {}
    queryParams: undefined
    body: {
      unsubscribeId: string
      emailType: EmailType
    }
    response: undefined
    meta: undefined
    expandable: undefined
  }
  unsubscribeUserFromNewsletter: {
    params: {
      token: string
      topic: BroadcastUnsubscribedTopics
      type: UnsubscribeTypes
    }
    queryParams: undefined
    body: undefined
    response: { unsubscribed: BroadcastUnsubscribedTopics }
    meta: undefined
    expandable: undefined
  }
  trafficRules: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: TrafficRule[]
    meta: undefined
    expandable: undefined
  }
  trafficRuleCreate: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: TrafficRuleCreatePayload
    response: TrafficRule
    meta: undefined
    expandable: undefined
  }
  trafficRuleDelete: {
    params: {
      subscriptionId: string
      trafficRuleId: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  trafficRuleUpdate: {
    params: {
      subscriptionId: string
      trafficRuleId: string
    }
    queryParams: undefined
    body: TrafficRuleCreatePayload & { id: string }
    response: TrafficRule
    meta: undefined
    expandable: undefined
  }
  trafficRuleBulkUpdate: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: {
      type: TrafficRuleType
      entities: TrafficRuleCreatePayload[]
    }
    response: TrafficRule[]
    meta: undefined
    expandable: undefined
  }
  usageCounters: {
    params: {
      subscriptionId: string
    }
    queryParams: {
      from?: string
      to?: string
      period?: UsageCounterPeriod
      type?: Array<UsageCounterType | BotdCounterType>
      product?: AppProduct
    }
    body: undefined
    response: UsageChartData
    meta: undefined
    expandable: undefined
  }
  timeline: {
    params: {
      subscriptionId: string
    }
    queryParams: {
      from?: string
      to?: string
      period?: UsageCounterPeriod
      metric: Array<'rps' | 'uniqueVisitors'>
    }
    body: undefined
    response: Partial<{
      rps: Array<{
        timestamp: string
        peak: number
        average: number
        throttled: string
        throttledPercentage: number
      }>
      uniqueVisitors: Array<{
        timestamp: string
        value: string
      }>
    }>
    meta: undefined
    expandable: undefined
  }
  accountSettings: {
    params: {}
    queryParams: undefined
    body: undefined
    response: AccountSettings
    meta: undefined
    expandable: undefined
  }
  accountSettingsUpdate: {
    params: {}
    queryParams: undefined
    body: Partial<AccountSettings>
    response: AccountSettings
    meta: undefined
    expandable: undefined
  }
  userSubscriptionSettings: {
    params: {
      subscriptionId: string
      settingsName: string
    }
    queryParams: undefined
    body: undefined
    response: ValueOf<UserSubscriptionSettings>
    meta: undefined
    expandable: undefined
  }
  userSubscriptionSettingsUpdate: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: { settings_name: keyof UserSubscriptionSettings; settings: ValueOf<UserSubscriptionSettings> }
    response: undefined
    meta: undefined
    expandable: undefined
  }
  userSettings: {
    params: {
      settingsName: string
    }
    queryParams: undefined
    body: undefined
    response: ValueOf<UserSettings>
    meta: undefined
    expandable: undefined
  }
  userSettingsUpdate: {
    params: undefined
    queryParams: undefined
    body: { settings_name: keyof UserSettings; settings: ValueOf<UserSettings> }
    response: undefined
    meta: undefined
    expandable: undefined
  }
  notifications: {
    params: {}
    queryParams: undefined
    body: undefined
    response: Notification[]
    meta: undefined
    expandable: undefined
  }
  // TODO could be deleted
  notificationsDelete: {
    params: { type: string }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  userContextGet: {
    params: {}
    queryParams: undefined
    body: undefined
    response: UserContext
    meta: undefined
    expandable: undefined
  }
  userStatsGet: {
    params: {}
    queryParams: undefined
    body: undefined
    response: UserStats
    meta: undefined
    expandable: undefined
  }
  invoices: {
    params: {}
    queryParams: PaginationParams & SortParams
    body: undefined
    response: InvoiceListItem[]
    meta: { count: number }
    expandable: undefined
  }
  invoiceGet: {
    params: {
      id: string
      subscriptionId?: string
    }
    queryParams: undefined
    body: undefined
    response: Invoice
    meta: undefined
    expandable: undefined
  }
  onboardingSkip: {
    params: {}
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  invoiceUpcomingGet: {
    params: { subscriptionId: string }
    queryParams: undefined
    body: undefined
    response: Invoice
    meta: undefined
    expandable: undefined
  }
  smartSignalsGet: {
    params: { subscriptionId: string }
    queryParams: { from: string; to: string }
    body: undefined
    response: SmartSignalResponse
    meta: undefined
    expandable: undefined
  }
  usersGet: {
    params: {}
    queryParams: undefined
    body: undefined
    response: User[]
    meta: undefined
    expandable: undefined
  }
  userUpdate: {
    params: {
      userId: string
    }
    queryParams: undefined
    body: EditUserFormData
    response: User
    meta: undefined
    expandable: undefined
  }
  userConsentUpdate: {
    params: {}
    queryParams: undefined
    body: UserConsentData
    response: undefined
    meta: undefined
    expandable: undefined
  }
  userDelete: {
    params: {
      userId: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  invitationConfirm: {
    params: {
      id: string
    }
    queryParams: undefined
    body: {
      password: string
      privacyPolicy: boolean
      termsOfService: boolean
      confirmationCode: string
    }
    response: AuthTokensWithUserContext
    meta: undefined
    expandable: undefined
  }
  invitationValidate: {
    params: {
      id: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  healthGet: {
    params: { subscriptionId: string }
    queryParams: undefined
    body: undefined
    response: SubscriptionHealth
    meta: undefined
    expandable: undefined
  }
  readmeLogin: {
    params: {}
    queryParams: undefined
    body: undefined
    response: ReadmeLogin
    meta: undefined
    expandable: undefined
  }
  integrationStatusGet: {
    params: { subscriptionId: string }
    queryParams: undefined
    body: undefined
    response: SubscriptionIntegrationStatus
    meta: undefined
    expandable: undefined
  }
  integrationStepSkip: {
    params: { subscriptionId: string }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  passwordStrength: {
    params: {}
    queryParams: undefined
    body: { password: string }
    response: PasswordStrengthResponse
    meta: undefined
    expandable: undefined
  }
  impersonate: {
    params: {}
    queryParams: undefined
    body: {
      oneTimeToken: string
    }
    response: Pick<AuthTokensWithUserContext, 'accessToken' | 'context'>
    meta: undefined
    expandable: undefined
  }
  visitsGet: {
    params: { subscriptionId: string }
    queryParams: NoSqlReversePagination & {
      trackTotalHits?: number
    } & VisitsFilter
    body: undefined
    response: PaginatedVisitsResponse
    meta: undefined
    expandable: undefined
  }
  visitGet: {
    params: { subscriptionId: string; requestId: string }
    queryParams: undefined
    body: undefined
    response: Visit
    meta: undefined
    expandable: undefined
  }
  visitorGet: {
    params: { subscriptionId: string; visitorId: string }
    queryParams: NoSqlPagination
    body: undefined
    response: PaginatedVisitsResponse
    meta: undefined
    expandable: undefined
  }
  pricesGet: {
    params: {}
    queryParams: { product: Product[] }
    body: undefined
    response: PricingData
    meta: undefined
    expandable: undefined
  }
  passwordUpdate: {
    params: {}
    queryParams: undefined
    body: UpdatePasswordFormData
    response: AuthTokens
    meta: undefined
    expandable: undefined
  }
  currentUserGet: {
    params: {}
    queryParams: undefined
    body: undefined
    response: User
    meta: undefined
    expandable: undefined
  }
  userFeedbackGet: {
    params: {}
    queryParams: undefined
    body: undefined
    response: UserFeedbackData
    meta: undefined
    expandable: undefined
  }
  userFeedbackUpdate: {
    body: UserFeedbackData
    queryParams: undefined
    params: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  emailNotificationSettingsGet: {
    params: {}
    queryParams: undefined
    body: undefined
    response: EmailNotificationsSettings
    meta: undefined
    expandable: undefined
  }
  emailNotificationSettingsUpdate: {
    params: {}
    queryParams: undefined
    body: EmailNotificationsUpdateBody
    response: EmailNotificationsSettings
    meta: undefined
    expandable: undefined
  }
  enableEmailNotifications: {
    params: {}
    queryParams: undefined
    body: {}
    response: undefined
    meta: undefined
    expandable: undefined
  }
  webhookEventsGet: {
    params: {
      subscriptionId: string
      webhookId: string
    }
    queryParams: NoSqlReversePagination & WebhookEventsFilter
    body: undefined
    response: PaginatedWebhookEventsResponse
    meta: undefined
    expandable: undefined
  }
  webhookEventGet: {
    params: {
      subscriptionId: string
      webhookId: string
      requestId: string
    }
    queryParams: WebhookEventsFilter
    body: undefined
    response: WebhookEvent
    meta: undefined
    expandable: undefined
  }
  visitorWebhookEventsGet: {
    params: {
      subscriptionId: string
      webhookId: string
      visitorId: string
    }
    queryParams: NoSqlPagination & WebhookEventsFilter
    body: undefined
    response: PaginatedWebhookEventsResponse
    meta: undefined
    expandable: undefined
  }
  webhookSendTestEvent: {
    params: {
      subscriptionId: string
      webhookId: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  webhookEventResend: {
    params: {
      subscriptionId: string
      webhookId: string
      requestId: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  // TODO could be deleted
  sendToDeveloper: {
    params: undefined
    queryParams: undefined
    body: {
      emails: string[]
    }
    response: undefined
    meta: undefined
    expandable: undefined
  }
  contactSupport: {
    params: undefined
    queryParams: undefined
    body: ContactSupportData
    response: undefined
    meta: undefined
    expandable: undefined
  }
  productFeedback: {
    params: undefined
    queryParams: undefined
    body: {
      currentSubscriptionId: string
      message: string
      isCallRequested: boolean
    }
    response: {
      ok: boolean
      error: any
    }
    meta: undefined
    expandable: undefined
  }
  cloudflareIntegrationGet: {
    params: { subscriptionId: string }
    queryParams: undefined
    body: undefined
    response: CloudflareIntegration | undefined
    meta: undefined
    expandable: undefined
  }
  updateCloudflareToken: {
    body: {
      subscriptionId: string
      apiToken: string
    }
    queryParams: undefined
    params: undefined
    response: CloudflareIntegration
    meta: undefined
    expandable: undefined
  }
  generateWorkerName: {
    params: undefined
    queryParams: undefined
    body: {
      subscriptionId: string
      accountId: string
      apiToken: string
      domain: string
    }
    response: WorkerData
    meta: undefined
    expandable: undefined
  }
  cloudflareVerifyToken: {
    params: {}
    queryParams: undefined
    body: {
      subscriptionId: string
      accountId: string
      apiToken: string
    }
    response: CloudflareApiTokenValidationResponse
    meta: undefined
    expandable: undefined
  }
  startDeploymentWorkerCF: {
    params: undefined
    queryParams: undefined
    body: {
      subscriptionId: string
      apiToken: string
      accountId: string
      zoneId: string
      workerName: string
      domain: string
    }
    response: WorkerDeploymentRequestResult
    meta: undefined
    expandable: undefined
  }
  cloudflareDeploymentStatus: {
    params: { id: string }
    queryParams: undefined
    body: undefined
    response: CloudflareDeploymentStatus
    meta: undefined
    expandable: undefined
  }
  cloudflareDomainsGet: {
    params: undefined
    queryParams: undefined
    body: {
      subscriptionId: string
      accountId: string
      apiToken: string
    }
    response: CloudflareDomainsResponse
    meta: undefined
    expandable: undefined
  }
  removeCloudflareIntegration: {
    params: {
      subscriptionId: string
      id: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    expandable: undefined
    meta: undefined
  }
  cloudflareSubdomainsGet: {
    params: undefined
    queryParams: undefined
    body: {
      subscriptionId: string
      accountId: string
      zoneId: string
      apiToken: string
    }
    response: CloudflareSubdomainsResponse
    meta: undefined
    expandable: undefined
  }
  cloudFrontIntegrationGet: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: CloudFrontIntegration
    expandable: undefined
    meta: undefined
  }
  cloudFrontIntegrationCreate: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: { managementLambdaPublicURL: string; managementLambdaAuthToken: string }
    response: undefined
    expandable: undefined
    meta: undefined
  }
  cloudFrontIntegrationUpdate: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: { managementLambdaPublicURL: string; managementLambdaAuthToken: string }
    response: undefined
    expandable: undefined
    meta: undefined
  }
  cloudFrontIntegrationDelete: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: undefined
    expandable: undefined
    meta: undefined
  }
  botdVisitsGet: {
    params: { subscriptionId: string }
    queryParams: NoSqlReversePagination & BotdVisitsFilter
    body: undefined
    response: PaginatedBotdVisitsResponse
    meta: undefined
    expandable: undefined
  }
  botdVisitGet: {
    params: {
      subscriptionId: string
      requestId: string
    }
    queryParams: undefined
    body: undefined
    response: BotdVisitDetails
    meta: undefined
    expandable: undefined
  }
  subscriptionDowngrade: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: boolean
    meta: undefined
    expandable: undefined
  }
  billingInfoUpdate: {
    params: undefined
    queryParams: undefined
    body: Partial<BillingInfo>
    response: BillingInfo
    meta: undefined
    expandable: undefined
  }
  billingInfoGet: {
    params: undefined
    queryParams: undefined
    body: undefined
    response: BillingInfo
    meta: undefined
    expandable: undefined
  }
  accountTransferGet: {
    params: undefined
    queryParams: undefined
    body: undefined
    response: AccountTransferRequest | undefined
    meta: undefined
    expandable: undefined
  }
  accountTransferIntent: {
    params: undefined
    queryParams: undefined
    body: undefined
    response: undefined
    meta: undefined
    expandable: undefined
  }
  accountTransferCreate: {
    params: undefined
    queryParams: undefined
    body: AccountTransferCreate
    response: boolean
    meta: undefined
    expandable: undefined
  }
  accountTransferDelete: {
    params: { id: string }
    queryParams: undefined
    body: undefined
    response: boolean
    meta: undefined
    expandable: undefined
  }
  accountTransferConfirm: {
    params: undefined
    queryParams: { id: string }
    body: undefined
    response: LoginResponse
    meta: undefined
    expandable: undefined
  }
  eventGet: {
    params: {
      subscriptionId: string
      requestId: string
    }
    queryParams: undefined
    body: undefined
    response: PlatformEvent
    meta: undefined
    expandable: undefined
  }
  identificationEventExport: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: IdentificationEventExportBody
    response: boolean
    meta: undefined
    expandable: undefined
  }
  clientSdkPerformance: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: ClientSDKPerformanceStatsResponse
    meta: undefined
    expandable: undefined
  }
  serviceStatus: {
    params: undefined
    queryParams: undefined
    body: undefined
    response: ServiceStatusResponse
    meta: undefined
    expandable: undefined
  }
  sendOneTimePassword: {
    params: undefined
    queryParams: undefined
    body: { oneTimePasswordFlow: string }
    response: boolean
    meta: undefined
    expandable: undefined
  }
  deleteAccount: {
    params: undefined
    queryParams: undefined
    body: { password: string }
    response: boolean
    meta: undefined
    expandable: undefined
  }
  workspaceEnvironments: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: WorkspaceEnvironment[]
    meta: undefined
    expandable: undefined
  }
  workspaceEnvironmentDelete: {
    params: {
      subscriptionId: string
      workspaceEnvironmentId: string
    }
    queryParams: undefined
    body: undefined
    response: boolean
    meta: undefined
    expandable: undefined
  }
  workspaceEnvironmentCreate: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: { name: string }
    response: WorkspaceEnvironment
    meta: undefined
    expandable: undefined
  }
  suspectScores: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: undefined
    response: SuspectScoreResponse
    meta: undefined
    expandable: undefined
  }
  suspectScoresUpdate: {
    params: {
      subscriptionId: string
    }
    queryParams: undefined
    body: UpdateSuspectScoreBody
    response: SuspectScoreResponse
    meta: undefined
    expandable: undefined
  }
  bdrMeetingScheduled: {
    params: undefined
    queryParams: undefined
    body: {
      mail: string
      ipRegion: string
      assigneeId?: string
      slot?: {
        start: number
        end: number
      }
      schedulingError?: boolean
      apiCalls?: string
      message?: string
    }
    response: {
      ok: boolean
      error: any
    }
    meta: undefined
    expandable: undefined
  }
  integrationRequest: {
    params: undefined
    queryParams: undefined
    body: {
      currentSubscriptionId: string
      integration: string
      useCase: string
    }
    response: {
      ok: boolean
      error: any
    }
    meta: undefined
    expandable: undefined
  }
  contactSalesEnterpriseClosure: {
    params: {}
    queryParams: undefined
    body: {}
    response: undefined
    meta: undefined
    expandable: undefined
  }
  getInvitations: {
    params: {}
    queryParams: undefined
    body: undefined
    response: UserInvitation[]
    meta: undefined
    expandable: undefined
  }
  getMyInvitations: {
    params: {}
    queryParams: undefined
    body: undefined
    response: UserInvitation[]
    meta: undefined
    expandable: undefined
  }
  confirmTransfer: {
    params: {}
    queryParams: undefined
    body: { invitationId: string; action: 'accept' | 'ignore'; declineAllFutureRequestsFromAccount?: boolean }
    response: LoginResponse
    meta: undefined
    expandable: undefined
  }
  createInvitation: {
    params: {}
    queryParams: undefined
    body: {}
    response: { ok: boolean }
    meta: undefined
    expandable: undefined
  }
  deleteInvitation: {
    params: { id: string }
    queryParams: undefined
    body: {}
    response: { ok: boolean }
    meta: undefined
    expandable: undefined
  }
  updateInvitation: {
    params: { id: string }
    queryParams: undefined
    body: { role: User['role']; name: string }
    response: { ok: boolean }
    meta: undefined
    expandable: undefined
  }
}

/**
 * List of names of express endpoint handlers.
 * Can be used both for client-side request generation and server-side endpoint handling.
 */
export type EndpointList = keyof APITypesMap

/**
 * List of names of express endpoint handlers that use NoSQL pagination.
 * Only endpoints included in this type can be used with the useNoSqlPaginationQuery hook.
 */
export type PaginatedEndpointList = keyof Pick<
  APITypesMap,
  'visitsGet' | 'visitorGet' | 'webhookEventsGet' | 'visitorWebhookEventsGet' | 'botdVisitsGet'
>

/** Constructs full API url from relative path */
export function apiUrl(relativePath: string): URL {
  const url = new URL(BASE_URL!)
  url.pathname = relativePath
  return url
}

export function isGenericError(error: unknown): error is GenericError {
  return !!(error as GenericError)?.code
}
