import { CustomDateRange, PredefinedRange } from 'helpers/dateRange'
import { useMemo } from 'react'

import { Value } from './useFailedApiCallsComparison'

export function useFailedApiCallsTooltipData(
  tooltipIndex: number | undefined,
  values: Value[],
  dateRange: CustomDateRange | PredefinedRange | undefined
) {
  return useMemo(() => {
    if (tooltipIndex == null) {
      return null
    }

    const throttled = values?.[tooltipIndex]?.throttled
    const restricted = values?.[tooltipIndex]?.restricted

    return {
      periodStart: values?.[tooltipIndex]?.timestamp ?? dateRange?.startDate,
      periodEnd: values?.[tooltipIndex + 1]?.timestamp ?? dateRange?.endDate,
      throttled,
      restricted,
    }
  }, [dateRange?.endDate, dateRange?.startDate, tooltipIndex, values])
}
