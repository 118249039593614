import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { getCountryRegion } from 'helpers/region'
import { useCallback, useMemo, useState } from 'react'

interface ChiliPiperBookingResponse {
  url?: string
  error?: boolean
}

interface ChiliPiperUser {
  email: string
}

export function useChiliPiper() {
  const [isBlocked, setIsBlocked] = useState(false)
  const [bookingUrl, setBookingUrl] = useState<string | null>(null)
  const [hasError, setHasError] = useState(false)
  const { data: visitorData } = useVisitorData({ extendedResult: true })

  const visitorRegion = useMemo(
    () => getCountryRegion(visitorData?.ipLocation?.country?.code?.toUpperCase() ?? 'US'),
    [visitorData?.ipLocation?.country?.code]
  )

  const checkAccess = useCallback(async (): Promise<boolean> => {
    try {
      const response = await fetch('https://api.chilipiper.com')
      return response.ok
    } catch {
      return false
    }
  }, [])

  const getBookingUrl = useCallback(
    async (userData: ChiliPiperUser): Promise<ChiliPiperBookingResponse> => {
      try {
        const [firstName, lastName] = userData.email.split('@')

        const response = await fetch('/api/redirect/chilipiper', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            firstName: firstName.slice(0, 20),
            lastName: lastName.slice(0, 20),
            email: userData.email,
            ipRegion: visitorRegion,
          }),
        })

        const data = await response.json()
        if (!data.url) {
          return { error: true }
        }

        return { url: data.url }
      } catch {
        return { error: true }
      }
    },
    [visitorRegion]
  )

  const initialize = useCallback(
    async (userData: ChiliPiperUser) => {
      const isAccessible = await checkAccess()
      setIsBlocked(!isAccessible)

      if (!isAccessible) {
        const { url, error } = await getBookingUrl(userData)
        if (error) {
          setHasError(true)
        } else if (url) {
          setBookingUrl(url)
        }
        return undefined
      }

      const script = document.createElement('script')
      script.src = 'https://js.chilipiper.com/marketing.js'
      script.async = true
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    },
    [checkAccess, getBookingUrl]
  )

  return {
    isBlocked,
    bookingUrl,
    hasError,
    initialize,
    visitorRegion,
  }
}
