import { bisector } from 'd3-array'
import { useCallback, useMemo, useState } from 'react'

export function useTimelineIndexer<T>(values: T[], timestampValueAccessor: (v: T) => number) {
  const bisectDate = useMemo(() => bisector<T, number>(timestampValueAccessor).left, [timestampValueAccessor])

  const [position, setPosition] = useState<number>()
  const [tooltipIndex, setTooltipIndex] = useState<number>()
  const handlePointer = useCallback(
    (x0: number | null, tooltipLeft: number | null) => {
      if (x0 == null || tooltipLeft == null) {
        setPosition(undefined)
        setTooltipIndex(undefined)
        return null
      }
      setPosition(tooltipLeft)

      const index = bisectDate(values, x0, 1)
      const d0 = values[index - 1]
      const d1 = values[index]

      if (d1 && timestampValueAccessor(d1)) {
        const isSecondCloser = x0 - timestampValueAccessor(d0) > timestampValueAccessor(d1) - x0
        const i = isSecondCloser ? index : index - 1
        setTooltipIndex(i)
        return timestampValueAccessor(values[i])
      }

      setTooltipIndex(index - 1)
      return timestampValueAccessor(values[index - 1])
    },
    [bisectDate, timestampValueAccessor, values]
  )

  return useMemo(
    () => ({
      position,
      tooltipIndex,
      handlePointer,
    }),
    [handlePointer, position, tooltipIndex]
  )
}
