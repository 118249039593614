import { Check } from 'lucide-react'
import React from 'react'
import {
  Collection,
  composeRenderProps,
  Header,
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
  ListBoxItemProps as AriaListBoxItemProps,
  ListBoxProps as AriaListBoxProps,
  Section,
  SectionProps,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import { composeTailwindRenderProps, focusRing } from './utils'

interface ListBoxProps<T> extends Omit<AriaListBoxProps<T>, 'layout' | 'orientation'> {}

export function ListBox<T extends object>({ children, ...props }: ListBoxProps<T>) {
  return (
    <AriaListBox
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        'outline-0 p-1 border border-gray-300 dark:border-zinc-600'
      )}
    >
      {children}
    </AriaListBox>
  )
}

export const itemStyles = tv({
  extend: focusRing,
  base: 'group relative flex items-center gap-8 cursor-default select-none py-1.5 px-2.5 will-change-transform text-base text-gray-1000 forced-color-adjust-none',
  variants: {
    isSelected: {
      false: 'text-slate-700 dark:text-zinc-300 hover:bg-slate-200 dark:hover:bg-zinc-700 -outline-offset-2',
      true: 'bg-gray-200 text-white forced-colors:bg-[Highlight] forced-colors:text-[HighlightText] [&:has(+[data-selected])]:rounded-b-none [&+[data-selected]]:rounded-t-none -outline-offset-4 outline-white dark:outline-white forced-colors:outline-[HighlightText]',
    },
    isDisabled: {
      true: 'text-slate-300 dark:text-zinc-600 forced-colors:text-[GrayText]',
    },
  },
})

export function ListBoxItem(props: AriaListBoxItemProps) {
  const textValue = props.textValue || (typeof props.children === 'string' ? props.children : undefined)
  return (
    <AriaListBoxItem {...props} textValue={textValue} className={itemStyles}>
      {composeRenderProps(props.children, (children) => (
        <>
          {children}
          <div className='absolute left-4 right-4 bottom-0 h-px bg-white/20 forced-colors:bg-[HighlightText] hidden [.group[data-selected]:has(+[data-selected])_&]:block' />
        </>
      ))}
    </AriaListBoxItem>
  )
}

export const dropdownItemStyles = tv({
  base: [
    'group',
    'flex',
    'gap-2 items-center justify-center whitespace-nowrap min-h-9 px-2 py-1',
    'rounded',
    'text-base text-gray-1000',
    '[&_svg]:text-gray-900',
    'transition-colors duration-100',
    'outline-none',
    'cursor-pointer',
  ],
  variants: {
    isFocused: {
      true: 'bg-gray-200',
    },
    isFocusVisible: {
      true: 'bg-gray-200',
    },
    isHovered: {
      true: 'bg-gray-200',
    },
    isPressed: {
      true: 'bg-gray-200',
    },
    isSelected: {
      true: 'bg-transparent',
    },
    isDisabled: {
      true: ['disabled:text-gray-600', '[&_svg]:disabled:text-gray-600', 'disabled:cursor-not-allowed'],
    },
  },
  compoundVariants: [
    {
      isFocused: false,
      isOpen: true,
      className: 'bg-gray-100',
    },
  ],
})

export interface DropdownItemProps extends AriaListBoxItemProps {
  prefix?: React.ReactNode
  suffix?: React.ReactNode
}

export function DropdownItem({ prefix, suffix, ...props }: DropdownItemProps) {
  const textValue = props.textValue || (typeof props.children === 'string' ? props.children : undefined)
  return (
    <AriaListBoxItem {...props} textValue={textValue} className={dropdownItemStyles}>
      {composeRenderProps(props.children, (children, { isSelected }) => (
        <>
          {prefix && <span className='flex-shrink-0'>{prefix}</span>}
          <span className='flex items-center flex-1 gap-2 font-medium truncate'>{children}</span>
          {suffix && !isSelected && <span className='ml-2 flex-shrink-0 [&_svg]:w-4 [&_svg]:h-4'>{suffix}</span>}
          <span className='flex items-center ml-2'>{isSelected && <Check size={16} />}</span>
        </>
      ))}
    </AriaListBoxItem>
  )
}

export interface DropdownSectionProps<T> extends SectionProps<T> {
  title?: string
}

export function DropdownSection<T extends object>(props: DropdownSectionProps<T>) {
  return (
    <Section className='first:-mt-[5px] mt-[5px]'>
      {props.title && (
        <Header className='flex items-center text-2xs font-medium tracking-wide uppercase text-gray-700 dark:text-gray-300 px-3 pt-2 truncate sticky -top-[5px] -mt-px -mx-1 z-1 backdrop-blur-md supports-[-moz-appearance:none]:bg-gray-100 border-t dark:border-t-gray-300 [&+*]:mt-1'>
          {props.title}
        </Header>
      )}
      <Collection items={props.items}>{props.children}</Collection>
    </Section>
  )
}
