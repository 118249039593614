import { LinkButton } from '@compass/components'
import { Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import { formatNum } from 'helpers/format'
import { useBillingQuota } from 'hooks'
import { KeyRoundIcon, SettingsIcon } from 'lucide-react'

import { ApplicationsOverviewSubscriptionDetails } from '../ApplicationsOverview/ApplicationsOverviewData'
import { getPlanTagLabel } from '../Tags/PlanTag'
import StatusTag from '../Tags/StatusTag'
import styles from './ApplicationsList.module.scss'

type SubscriptionItemProps = {
  subscription: ApplicationsOverviewSubscriptionDetails
}

function SubscriptionItem({ subscription }: SubscriptionItemProps) {
  const { currentUsage } = useBillingQuota(subscription)

  return (
    <div key={subscription.id} className={styles.applicationsListItem}>
      <div className={clsx(styles.applicationsListItemRow, styles.applicationsListItemRowHeader)}>
        <div className={styles.property}>
          <Typography variant='bodyMMedium' className={styles.primaryLabel}>
            {subscription.name}
          </Typography>
          <Typography variant='bodyM' className={styles.secondaryLabel}>
            {subscription.domain}
          </Typography>
        </div>

        <StatusTag status={subscription.status} />
      </div>
      <div className={clsx(styles.applicationsListItemRow, styles.applicationsListItemRowDetails)}>
        <div className={styles.propertySet}>
          <div className={styles.property}>
            <Typography variant='bodyS' className={styles.subheaderLabel}>
              Plan
            </Typography>
            <Typography variant='bodyMMedium' className={styles.secondaryLabel}>
              {getPlanTagLabel(subscription)}
            </Typography>
          </div>
          <div className={styles.property}>
            <Typography variant='bodyS' className={styles.subheaderLabel}>
              Usage
            </Typography>
            <Typography variant='bodyMMedium' className={styles.secondaryLabel}>
              {currentUsage ? formatNum(currentUsage) : '-'}
            </Typography>
          </div>
        </div>

        <div className={styles.actionSet}>
          <LinkButton variant='outline' href={buildRoute(AppRoute.ApiKeys, { subscriptionId: subscription.id })}>
            <KeyRoundIcon />
            Keys
          </LinkButton>

          <LinkButton
            variant='outline'
            href={buildRoute(AppRoute.SubscriptionSettings, { subscriptionId: subscription.id })}
          >
            <SettingsIcon />
            Manage
          </LinkButton>
        </div>
      </div>
    </div>
  )
}

export type ApplicationsListProps = {
  subscriptions: ApplicationsOverviewSubscriptionDetails[]
}

export default function ApplicationsList({ subscriptions }: ApplicationsListProps) {
  return (
    <div>
      {subscriptions.map((subscription) => (
        <SubscriptionItem key={subscription.id} subscription={subscription} />
      ))}
    </div>
  )
}
