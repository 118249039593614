import { ButtonOrLink } from '@compass/ButtonOrLink'
import { SubHeader } from 'features/commonUI'
import { BookOpen } from 'lucide-react'
import { Discount, SubscriptionType } from 'models'

import { UsageEntry } from '../../types'
import { UsageMeter } from './UsageMeter/UsageMeter'
import { UsageTable } from './UsageTable/UsageTable'

export interface PlanUsageProps {
  title: string | React.ReactNode
  description: string
  entries: Array<UsageEntry>
  totalUsage: number
  projected?: number
  action?: string
  onAction?: () => void
  actionTo?: string
  isLoading?: boolean
  subscriptionType?: SubscriptionType
  isRelatedVisitors?: boolean
  docsUrl?: string
  appliedDiscount?: Discount
}

interface ActionButtonProps {
  action?: string
  onAction?: () => void
  actionTo?: string
  isLoading?: boolean
}

const ActionButton = ({ action, onAction, actionTo, isLoading }: ActionButtonProps) => {
  if (!action || !onAction) return null

  return (
    <ButtonOrLink
      className='mb-4'
      variant='secondary'
      onPress={onAction}
      href={actionTo}
      isDisabled={isLoading}
      fullWidth
    >
      {action}
    </ButtonOrLink>
  )
}

export function PlanUsage({
  title,
  description,
  entries,
  totalUsage,
  projected,
  action,
  onAction,
  actionTo,
  isLoading,
  subscriptionType,
  isRelatedVisitors,
  docsUrl,
  appliedDiscount,
}: PlanUsageProps) {
  return (
    <div>
      <SubHeader
        title={title}
        description={description}
        className='mb-6'
        actionsPosition='top'
        keepRowLayout={true}
        actions={
          docsUrl && (
            <ButtonOrLink
              variant='ghost'
              tooltip='Go to billing docs'
              aria-label='Go to billing docs'
              href={docsUrl}
              target='_blank'
              isIcon
              isDisabled={isLoading}
            >
              <BookOpen />
            </ButtonOrLink>
          )
        }
      />
      <ActionButton action={action} onAction={onAction} actionTo={actionTo} isLoading={isLoading} />
      <UsageMeter
        totalUsage={totalUsage}
        projected={projected}
        entries={entries}
        subscriptionType={subscriptionType}
        appliedDiscount={appliedDiscount}
        isLoading={isLoading}
      />
      {!isLoading && entries.length > 0 && (
        <div className='overflow-hidden overflow-x-auto mt-8 rounded'>
          <UsageTable
            entries={entries}
            subscriptionType={subscriptionType}
            isRelatedVisitors={isRelatedVisitors}
            appliedDiscount={appliedDiscount}
          />
        </div>
      )}
    </div>
  )
}
