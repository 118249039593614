import { Button } from '@compass/Button'
import { Stack } from '@compass/components'
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogTitle, ModalOverlay } from '@compass/Modal'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { UserInvitation } from 'models'
import { useState } from 'react'

interface IgnoreInvitationModalProps {
  isOpen: boolean
  onClose: () => void
  invitation: UserInvitation
  isLoading: boolean
  onIgnore: (invitationId: string, ingoreFutureInvitations: boolean) => void
}

export function IgnoreInvitationModal({
  isOpen,
  onClose,
  invitation,
  isLoading,
  onIgnore,
}: IgnoreInvitationModalProps) {
  const [ignoreFutureInvitations, setIgnoreFutureInvitations] = useState(false)
  return (
    <ModalOverlay isOpen={isOpen} isDismissable onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Ignore this invitation?</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Stack gap={4} direction='column'>
            <Typography>
              Are you sure you want to ignore an invitation from {invitation.invitedByUser.name} to join their
              Fingerprint account?
            </Typography>
            <FormControlLabel
              control={<Checkbox onChange={() => setIgnoreFutureInvitations((check) => !check)} />}
              label={<Typography variant='bodyS'>Ignore all future invitations from this sender</Typography>}
            />
          </Stack>
        </DialogBody>
        <DialogFooter>
          <Button variant='secondary' onPress={onClose}>
            Cancel
          </Button>
          <Button onPress={() => onIgnore(invitation.id, ignoreFutureInvitations)} isLoading={isLoading}>
            Ignore invite
          </Button>
        </DialogFooter>
      </DialogContent>
    </ModalOverlay>
  )
}
