import { Badge, Button, SelectItem, SelectListBox, SelectPopover, Stack } from '@compass/components'
import clsx from 'clsx'
import { useUiPreferences } from 'hooks'
import { ChevronsUpDownIcon } from 'lucide-react'
import { useCallback, useState } from 'react'
import { Select } from 'react-aria-components'

export type OverviewChartType = 'api_usage' | 'events_per_visitor' | 'failed_api_calls' | 'rps'
export type OverviewChartSelectOption = {
  key: OverviewChartType
  label: string
}
export const selectOptions: OverviewChartSelectOption[] = [
  { key: 'api_usage', label: 'API usage' },
  { key: 'events_per_visitor', label: 'Events per visitor' },
  { key: 'failed_api_calls', label: 'Failed API calls' },
  { key: 'rps', label: 'Requests per second (RPS)' },
]
export function InsightsChartSelector({
  selectedChart = 'api_usage',
  onSelectionChange,
  className,
}: {
  selectedChart?: OverviewChartType
  onSelectionChange: (selected: OverviewChartType) => void
  className?: string
}) {
  const { uiPreferences, updateUiPreferences } = useUiPreferences()
  const [isOpen, setIsOpen] = useState(false)

  const onOpenChange = useCallback(
    (open: boolean) => {
      updateUiPreferences({ isChartSelectorClicked: true })
      setIsOpen(open)
    },
    [updateUiPreferences]
  )

  return (
    <Stack gap={2} className={clsx('items-center', className)}>
      {uiPreferences.isChartSelectorClicked !== true ? (
        <Badge tone='default' variant='solid' className='motion-safe:animate-bounceXLeft mt-0.5'>
          New →
        </Badge>
      ) : null}
      <Select<OverviewChartSelectOption>
        aria-label='Displayed chart'
        selectedKey={selectedChart}
        onSelectionChange={onSelectionChange}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <Button isIcon variant='ghost' aria-label='Change displayed chart' onPress={() => setIsOpen(true)}>
          <ChevronsUpDownIcon />
        </Button>
        <SelectPopover>
          <SelectListBox>
            {selectOptions?.map((option) => (
              <SelectItem key={option.key} id={option.key} value={option} isDisabled={option.key === selectedChart}>
                {option.label}
              </SelectItem>
            ))}
          </SelectListBox>
        </SelectPopover>
      </Select>
    </Stack>
  )
}
